<template>
  <div>
    <a id="top"></a>

    <v-row justify="center">
      <v-col cols="8">
        <h2>Kalkulator biegowy</h2>
      </v-col>
      <v-col cols="8">
        <p>
          Poniżej kilka bardzo prostych kalkulatorów biegowych, które można też
          nazwać kalkulatorami tempa.
        </p>
        <p>Dla kilku predefiniowanych długości biegu:</p>

        <v-btn-toggle v-model="text" tile color="deep-purple accent-3" group>
          <v-btn text color="primary" href="#5km"> 5km </v-btn>

          <v-btn text color="primary" href="#10km"> 10km </v-btn>

          <v-btn text color="primary" href="#halfmarathon">21,0975km (półmaraton) </v-btn>

          <v-btn text color="primary" href="#marathon"> 42,195km (maraton) </v-btn>
        </v-btn-toggle>

        <br />
        <p>można dowolnie wyznaczyć:</p>
        <ul>
          <li>prędkość biegu</li>
          <li>
            tempo biegu - czyli średnio ile minut potrzebujemy na 1 kilomter
          </li>
          <li>
            czas - czas w jaki pokonamy, bądź chcemy pokonać, określony dystans
          </li>
        </ul>
        <p>
          "Łapiąc" za dowolny suwak możemy zmieniać daną wielkość i obserwować
          jak wpływa to na pozostałe z nich.
        </p>
        <p>
          Jest jedna niedoskonałość tego typu obliczeń, do której muszę się
          przyznać, błędy zaokrąglenia przy przeliczaniu i dlatego nie zawsze
          wynik jest w 100% idealny, ale jak to mawiają "to nie apteka".
        </p>
        <p><strong>Udanej zabawy!</strong></p>
      </v-col>
      <v-col cols="4">
        <v-img
            max-width="480"
            src="https://www.runisfun.eu/_images/athlete.png"
          ></v-img>
      </v-col>
    </v-row>
    <br />

    <!-- 5km -->
    <a id="5km"></a>
    <v-card class="mx-auto" max-width="600">
      <v-toolbar flat dense>
        <v-toolbar-title>
          <span class="subheading">Dystans: 5km</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span class="display-3 font-weight-light" v-text="speed_5k"></span>
            <span class="subheading font-weight-light mr-1"
              >prędkość [km/h]</span
            >
          </v-col>
        </v-row>

        <v-slider
          v-model="speed_5k"
          track-color="grey"
          always-dirty
          min="6"
          max="15"
          step="0.1"
          @input="speed_change_5k"
        >
        </v-slider>
        <v-divider></v-divider>

        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span
              class="display-3 font-weight-light"
              v-text="paste_5k_time"
            ></span>
            <span class="subheading font-weight-light mr-1"
              >tempo [min/km]</span
            >
          </v-col>
        </v-row>

        <v-slider
          v-model="paste_5k_sec"
          track-color="grey"
          always-dirty
          min="240"
          max="600"
          @input="paste_change_5k"
        >
        </v-slider>
        <v-divider></v-divider>

        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span
              class="display-3 font-weight-light"
              v-text="time_5k_time"
            ></span>
            <span class="subheading font-weight-light mr-1">czas [mm:ss]</span>
          </v-col>
        </v-row>

        <v-slider
          v-model="time_5k_sec"
          track-color="grey"
          always-dirty
          min="1200"
          max="3000"
          @input="time_change_5k"
        >
        </v-slider>
      </v-card-text>
    </v-card>

    <v-btn
      icon
      color="blue"
      right
      href="#top"
    >
      <v-icon>mdi-arrow-up-bold</v-icon>
    </v-btn>

    <!-- 10km -->
    <a id="10km"></a>
    <br />
    <v-divider />
    <br />
    <v-card class="mx-auto" max-width="600">
      <v-toolbar flat dense>
        <v-toolbar-title>
          <span class="subheading">Dystans: 10km</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span class="display-3 font-weight-light" v-text="speed_10k"></span>
            <span class="subheading font-weight-light mr-1"
              >prędkość [km/h]</span
            >
          </v-col>
        </v-row>

        <v-slider
          v-model="speed_10k"
          track-color="grey"
          always-dirty
          min="6"
          max="15"
          step="0.1"
          @input="speed_change_10k"
        >
        </v-slider>
        <v-divider></v-divider>

        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span
              class="display-3 font-weight-light"
              v-text="paste_10k_time"
            ></span>
            <span class="subheading font-weight-light mr-1"
              >tempo [min/km]</span
            >
          </v-col>
        </v-row>

        <v-slider
          v-model="paste_10k_sec"
          track-color="grey"
          always-dirty
          min="240"
          max="600"
          @input="paste_change_10k"
        >
        </v-slider>
        <v-divider></v-divider>

        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span
              class="display-3 font-weight-light"
              v-text="time_10k_time"
            ></span>
            <span class="subheading font-weight-light mr-1">czas [mm:ss]</span>
          </v-col>
        </v-row>

        <v-slider
          v-model="time_10k_sec"
          track-color="grey"
          always-dirty
          min="2280"
          max="6000"
          @input="time_change_10k"
        >
        </v-slider>
      </v-card-text>
    </v-card>

    <v-btn
      icon
      color="blue"
      right
      href="#top"
    >
      <v-icon>mdi-arrow-up-bold</v-icon>
    </v-btn>

    <!-- half marathon -->
    <a id="halfmarathon"></a>
    <br />
    <v-divider />
    <br />
    <v-card class="mx-auto" max-width="600">
      <v-toolbar flat dense>
        <v-toolbar-title>
          <span class="subheading">Dystans: 21,0975km (półmaraton)</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span class="display-3 font-weight-light" v-text="speed_21k"></span>
            <span class="subheading font-weight-light mr-1"
              >prędkość [km/h]</span
            >
          </v-col>
        </v-row>

        <v-slider
          v-model="speed_21k"
          track-color="grey"
          always-dirty
          min="6"
          max="22.5"
          step="0.1"
          @input="speed_change_21k"
        >
        </v-slider>
        <v-divider></v-divider>

        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span
              class="display-3 font-weight-light"
              v-text="paste_21k_time"
            ></span>
            <span class="subheading font-weight-light mr-1"
              >tempo [min/km]</span
            >
          </v-col>
        </v-row>

        <v-slider
          v-model="paste_21k_sec"
          track-color="grey"
          always-dirty
          min="160"
          max="600"
          @input="paste_change_21k"
        >
        </v-slider>
        <v-divider></v-divider>

        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span
              class="display-3 font-weight-light"
              v-text="time_21k_time"
            ></span>
            <span class="subheading font-weight-light mr-1">czas [mm:ss]</span>
          </v-col>
        </v-row>

        <v-slider
          v-model="time_21k_sec"
          track-color="grey"
          always-dirty
          min="3300"
          max="12600"
          @input="time_change_21k"
        >
        </v-slider>
      </v-card-text>
    </v-card>

    <v-btn
      icon
      color="blue"
      right
      href="#top"
    >
      <v-icon>mdi-arrow-up-bold</v-icon>
    </v-btn>

    <!-- marathon -->
  <a id="marathon"></a>
    <br />
    <v-divider />
    <br />
    <v-card class="mx-auto" max-width="600">
      <v-toolbar flat dense>
        <v-toolbar-title>
          <span class="subheading">Dystans: 42,195km (maraton)</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span class="display-3 font-weight-light" v-text="speed_42k"></span>
            <span class="subheading font-weight-light mr-1"
              >prędkość [km/h]</span
            >
          </v-col>
        </v-row>

        <v-slider
          v-model="speed_42k"
          track-color="grey"
          always-dirty
          min="6"
          max="22.5"
          step="0.1"
          @input="speed_change_42k"
        >
        </v-slider>
        <v-divider></v-divider>

        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span
              class="display-3 font-weight-light"
              v-text="paste_42k_time"
            ></span>
            <span class="subheading font-weight-light mr-1"
              >tempo [min/km]</span
            >
          </v-col>
        </v-row>

        <v-slider
          v-model="paste_42k_sec"
          track-color="grey"
          always-dirty
          min="160"
          max="600"
          @input="paste_change_42k"
        >
        </v-slider>
        <v-divider></v-divider>

        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span
              class="display-3 font-weight-light"
              v-text="time_42k_time"
            ></span>
            <span class="subheading font-weight-light mr-1">czas [mm:ss]</span>
          </v-col>
        </v-row>

        <v-slider
          v-model="time_42k_sec"
          track-color="grey"
          always-dirty
          min="6000"
          max="25200"
          @input="time_change_42k"
        >
        </v-slider>
      </v-card-text>
    </v-card>
    <v-btn
      icon
      color="blue"
      right
      href="#top"
    >
      <v-icon>mdi-arrow-up-bold</v-icon>
    </v-btn>
  </div>
</template>


<script>
export default {
  name: "RunCalculator",
  components: {},

  mounted() {
    //fill paste 5k
    this.convertPaste5kToTime();

    //fill time 5k
    this.convertTime5kToTime();

    //fill paste 10k
    this.convertPaste10kToTime();

    //fill time 10k
    this.convertTime10kToTime();

    //fill paste 21k
    this.convertPaste21kToTime();

    //fill time 21k
    this.convertTime21kToTime();

    //fill paste 42k
    this.convertPaste42kToTime();

    //fill time 42k
    this.convertTime42kToTime();
  },

  methods: {
    convertPaste5kToTime() {
      let date = new Date(null);
      date.setSeconds(this.paste_5k_sec);
      this.paste_5k_time = date.toISOString().substring(14, 19);
    },

    convertTime5kToTime() {
      let date = new Date(null);
      date.setSeconds(this.time_5k_sec);
      this.time_5k_time = date.toISOString().substring(14, 19);
    },

    time_change_5k() {
      this.convertTime5kToTime();

      //set up new speed
      this.speed_5k = parseFloat(5 / (this.time_5k_sec / (60 * 60))).toFixed(1);

      //set up new paste
      this.paste_5k_sec = this.time_5k_sec / 5;
    },
    speed_change_5k() {
      //calculate time
      this.time_5k_sec = parseFloat((5 / this.speed_5k) * (60 * 60)).toFixed(0);
    },

    paste_change_5k() {
      this.convertPaste5kToTime();

      //calculate time
      this.speed_5k = parseFloat((60 * 60) / this.paste_5k_sec).toFixed(1);
    },

    //10km
    convertPaste10kToTime() {
      let date = new Date(null);
      date.setSeconds(this.paste_10k_sec);
      this.paste_10k_time = date.toISOString().substring(14, 19);
    },

    convertTime10kToTime() {
      let date = new Date(null);
      date.setSeconds(this.time_10k_sec);
      this.time_10k_time = date.toISOString().substring(11, 19);
    },

    time_change_10k() {
      this.convertTime10kToTime();

      //set up new speed
      this.speed_10k = parseFloat(10 / (this.time_10k_sec / (60 * 60))).toFixed(
        1
      );

      //set up new paste
      this.paste_10k_sec = this.time_10k_sec / 10;
    },
    speed_change_10k() {
      //calculate time
      this.time_10k_sec = parseFloat((10 / this.speed_10k) * (60 * 60)).toFixed(
        0
      );
    },

    paste_change_10k() {
      this.convertPaste10kToTime();

      //calculate time
      this.speed_10k = parseFloat((60 * 60) / this.paste_10k_sec).toFixed(1);
    },

    //21km
    convertPaste21kToTime() {
      let date = new Date(null);
      date.setSeconds(this.paste_21k_sec);
      this.paste_21k_time = date.toISOString().substring(14, 19);
    },

    convertTime21kToTime() {
      let date = new Date(null);
      date.setSeconds(this.time_21k_sec);
      this.time_21k_time = date.toISOString().substring(11, 19);
    },

    time_change_21k() {
      this.convertTime21kToTime();

      //set up new speed
      this.speed_21k = parseFloat(
        21.0975 / (this.time_21k_sec / (60 * 60))
      ).toFixed(1);

      //set up new paste
      this.paste_21k_sec = this.time_21k_sec / 21.0975;
    },
    speed_change_21k() {
      //calculate time
      this.time_21k_sec = parseFloat(
        (21.0975 / this.speed_21k) * (60 * 60)
      ).toFixed(0);
    },

    paste_change_21k() {
      this.convertPaste21kToTime();

      //calculate time
      this.speed_21k = parseFloat((60 * 60) / this.paste_21k_sec).toFixed(1);
    },

    //42km
    convertPaste42kToTime() {
      let date = new Date(null);
      date.setSeconds(this.paste_42k_sec);
      this.paste_42k_time = date.toISOString().substring(14, 19);
    },

    convertTime42kToTime() {
      let date = new Date(null);
      date.setSeconds(this.time_42k_sec);
      this.time_42k_time = date.toISOString().substring(11, 19);
    },

    time_change_42k() {
      this.convertTime42kToTime();

      //set up new speed
      this.speed_42k = parseFloat(
        42.195 / (this.time_42k_sec / (60 * 60))
      ).toFixed(1);

      //set up new paste
      this.paste_42k_sec = this.time_42k_sec / 42.195;
    },
    speed_change_42k() {
      //calculate time
      this.time_42k_sec = parseFloat(
        (42.195 / this.speed_42k) * (60 * 60)
      ).toFixed(0);
    },

    paste_change_42k() {
      this.convertPaste42kToTime();

      //calculate time
      this.speed_42k = parseFloat((60 * 60) / this.paste_42k_sec).toFixed(1);
    },
  },

  computed: {},

  data: () => ({
    speed_5k: 10,
    paste_5k_sec: 360, //in secounds so will be convertef to min/km
    paste_5k_time: null,
    time_5k_sec: 1800, //in sec
    time_5k_time: null,

    //10km
    speed_10k: 10,
    paste_10k_sec: 360, //in secounds so will be convertef to min/km
    paste_10k_time: null,
    time_10k_sec: 3600, //in sec
    time_10k_time: null,

    //21km
    speed_21k: 10,
    paste_21k_sec: 360, //in secounds so will be convertef to min/km
    paste_21k_time: null,
    time_21k_sec: 7560, //in sec
    time_21k_time: null,

    //42km
    speed_42k: 10,
    paste_42k_sec: 360, //in secounds so will be convertef to min/km
    paste_42k_time: null,
    time_42k_sec: 15120, //in sec
    time_42k_time: null,
  }),
};
</script>