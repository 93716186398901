<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <v-list>
        <v-list-item-group color="indigo">
          <v-list-item to="/">
            <v-list-item-action>
              <v-icon color="blue">mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Strona główna</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/Aktualnosci">
            <v-list-item-action>
              <v-icon color="blue">mdi-information</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Aktualności</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/czemu-warto-biegac">
            <v-list-item-action>
              <v-icon color="blue">mdi-comment-question-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Czemu warto biegać</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/Kontakt">
            <v-list-item-action>
              <v-icon color="blue">mdi-email</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Kontakt</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-list-item to="/kalkulator-BMI">
            <v-list-item-action>
              <v-icon color="green accent-4">mdi-weight-kilogram</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Kalkulator BMI</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/kalkulator-BMR-CPM">
            <v-list-item-action>
              <v-icon color="green accent-4">mdi-weight-kilogram</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>BMR, CPM</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/kalkulator-biegowy">
            <v-list-item-action>
              <v-icon color="green accent-4">mdi-calculator</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>kalkulator biegowy</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/strefy-tetna">
            <v-list-item-action>
              <v-icon color="light-green accent-4">mdi-trending-up</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Strefy tętna</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/Test-Coopera">
            <v-list-item-action>
              <v-icon color="green accent-4">mdi-battery-charging-100</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Test Coopera</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/pulap-tlenowy">
            <v-list-item-action>
              <v-icon color="green accent-4">mdi-gas-cylinder</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Pułap tlenowy</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/kalkulator-spalania-kalorii">
            <v-list-item-action>
              <v-icon color="green accent-4">mdi-run</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Kalkulator kalorii</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider />

        <v-list-item to="/kalkulator-tetna-maksymalnego">
          <v-list-item-action>
            <v-icon color="red accent-4">mdi-heart-pulse</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Kalkulator tętna max</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/Metoda-Karvonena">
          <v-list-item-action>
            <v-icon color="red accent-4">mdi-heart-pulse</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Metoda Karvonena</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item to="/jakosc-powietrza-pogoda">
          <v-list-item-action>
            <v-icon color="amber darken-3">mdi-thermometer</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Za oknem</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="light-blue lighten-2" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Bieganie jest fajne!</v-toolbar-title>
      <v-spacer />
      <v-img
        max-width="185"
        src="https://www.runisfun.eu/_images/logo.png"
      ></v-img>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer color="light-blue lighten-1" dark>
      <v-spacer />
      {{ quotesTable[getRundomNumber()] }}
      <v-spacer />
      {{ new Date().getFullYear().toString() }} — &copy; Copyright Maciej
      Wojciechowski
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {},

  methods: {
    getRundomNumber() {
      return (this.tableIndex = Math.floor(
        Math.random() * this.quotesTable.length
      ));
    },
  },

  components: {},

  data: () => ({
    drawer: null,
    quotesTable: [
      "Biegaj z głową",
      "Jeśli biegnie Ci się wyjątkowo łatwo to albo masz z górki albo wiatr w plecy",
      '"Im więcej potu na ćwiczeniach, tym mniej krwi w boju." - Howard E. Wasdin',
      '"Pudłujesz 100% strzałów, jeśli w ogóle ich nie wykonujesz." - Wayne Gretzky',
      '"Kiedyś – nie ma takiego dnia tygodnia." - Janet Dailey',
      '"Nigdy nie rezygnuj z celu tylko dlatego, że osiągnięcie go wymaga czasu. Czas i tak upłynie." - H. Jackson Brown',
      '"Zawsze znajdzie się odpowiednia filozofia do braku odwagi." - Albert Camus',
      '"Praca rodzi pomysły." - Jim Rohn',
      '"Jeśli już musisz, to przegap posiłek, ale nie przegap książki." - Jim Rohn',
      '"Nie musisz być wielkim, by zacząć, ale musisz zacząć, aby być wielkim." - Zig Ziglar',
      '"Za rok będziesz żałował, że nie zacząłeś właśnie dziś." - Karen Lamb',
      '"Jeśli przechodzisz przez piekło, nie zatrzymuj się." - Winston Churchill',
      '"Albo wygram albo zdechnę." - Justyna Kowalczyk',
      '"Marzenie jest formą planowania. - Gloria Steinem',
      '"Jeśli ktoś uśmiecha się do ciebie, odpowiedz mu tym samym." - Dolly Parton',
      '"Tyle wiemy o sobie, ile nas sprawdzono." - Wisława Szymborska',
      '"Życie jest za piękne, aby żyć normalnie." - Maria Czubaszek',
      '"I tylko jedno może unicestwić marzenie – strach przed porażką." - Paulo Coelho',
      '"Nie wyobrażam sobie, aby można było osiągnąć sukces, nie będąc konsekwentnym i upartym." - Irena Eris',
      '"Mistrzowie grają dotąd, aż grają dobrze." - Billie Jean King',
      '"Cierpliwość, wytrwałość i pot tworzą niepokonaną kombinację sukcesu." - Napoleon Bonaparte',
      '"Najtrudniejsze jest zdecydowanie się na działanie. Reszta to już tylko kwestia wytrwałości." - Amelia Earhart',
      '"Determinacja to poniesienie porażki 19 razy i odniesienie sukcesu za 20. razem." - Julie Andrews',
      '"Motywacja jest tym co pozwala Ci zacząć. Nawyk jest tym co pozwala Ci wytrwać!" - Stephen Covey',
      '"Lepiej zaliczać się do niektórych, niż do wszystkich." - Andrzej Sapkowski',
      '"Nie licz dni, spraw by dni się liczyły." - Muhammad Ali',
      '"Nie ma znaczenia jak wolno idziesz, tak długo jak nie przestajesz." - Konfucjusz',
      '"Miej odwagę żyć. Umrzeć potrafi każdy." - Robert Cody',
      '"Podróż tysiąca mil zaczyna się od jednego kroku." - Lao Tzu',
      '"Codziennie rób jedną rzecz, której się boisz." - Eleanor Roosevelt',
      '"Wiek nie ma znaczenia, chyba że jesteś serem." - Billie Burke',
      '"Ignoranti quem portum petat nullus suus ventus est – dla kogoś, kto nie wie, do jakiego portu zmierza, każdy wiatr jest niepomyślny" - Seneka',

      '"Nie to, co już wiemy, lecz to, co chcemy wiedzieć, świadczy o naszej mądrości" - Władysław Grzeszczyk',
      '"Nie pozwól, żeby język wyprzedał myśl" - Antoni Czechow',
      '"Zamiast karać ludzi za błędy, pokaż im inną drogę postrzegania prawdy" - ?',
      '"Czasem coś mądrego może powiedzieć ktoś na tyle głupi, żeby się pod tym zapomnieć podpisać" - Niekryty Krytyk',
    ],
  }),
};
</script>
