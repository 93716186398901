<template>
    <div>
        
      <v-container>
        <v-row justify="center">
          <v-col
            cols="8"
          >
            <h2>Kalkulator tętna maksymalnego</h2>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col
            cols="8"
          >
            <p>Znajdziecie tutaj wszystkie formuły, jakie udało mi się znaleźć w internecie. Pamiętajcie, że to tylko wzory matematyczne a nie prawdziwe badanie! Potraktujcie to jako "referencję". </p>
            <p>Tętno maksymalne może być użyte do wyznaczenia <router-link to="/strefy-tetna">stref tętna</router-link> a co za tym idzie trening powinien być bardziej efektywny i bezpieczniejszy.</p>
            <p>Tętno wyliczone tutaj zostanie "zapamiętane" przez przeglądarkę więc wchowdząc w strefy tętna wartość ta zostanie tam automatycznie wpisana.</p>
          </v-col>
        </v-row>
      </v-container>

      
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            <v-select
              :items="items"
              label="Płeć"
              v-model="sex"
              @input="set_session_sex"
            ></v-select>
          </v-col>

          <v-col cols="6" sm="4" md="3">
            <v-text-field
              label="Wiek"
              v-model= "age"
              @input="set_session_age"
            ></v-text-field>
          </v-col>

        </v-row>

      </v-col>

      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            <v-text-field
              label="Waga [kg]"
              v-model= "weight"
              @input="set_session_weight"
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4" md="3">
            <v-text-field
              label="Wzrost [cm]"
              v-model= "height"
              @input="set_session_height"
            ></v-text-field>


          </v-col>


        </v-row>

        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            <v-text-field
              label="Współczynnik, wyznaczjący max strefę wysiłku"
              v-model= "factor"
              @input="set_session_factor"
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4" md="3">
              
          </v-col>

        </v-row>
      </v-col>


      <v-col cols="12">




        <v-row justify="center">
          <v-col cols="6" sm="4" md="3" style="background: #0277BD; color: white">
            <span >Średnie tętno MAX</span>
            <span style="font-size: 12px"> Średnia z poniższych</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3" style="background: #0277BD; color: white">
             <span v-if="getAverage > 0" style="font-weight: bold">
                {{getAverage}} ({{factor}} = {{(factor * getAverage).toFixed(2)}})
              </span>
          </v-col>
        </v-row>  

        <v-row justify="center" >
          <v-col cols="6" sm="4" md="3">
            Formuła 1
            <span style="font-size: 12px">210 - (0.5 * wiek) - (0.22 * wzrost)</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3">
             <span v-if="getFormulaOne > 0">
                {{getFormulaOne}}  ({{factor}} = {{(factor * getFormulaOne).toFixed(2)}})
                  
                  <v-icon color="red accent-4" class="buttons-class" v-if="getAverage > 0 && getFormulaOne > getAverage">call_made</v-icon>
                  <v-icon color="green" class="buttons-class" v-if="getAverage > 0 && getFormulaOne < getAverage">call_received</v-icon>

             </span>

          </v-col>

        </v-row>   

        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            Formuła 2
            <span style="font-size: 12px">220 - wiek</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3">
             <span v-if="getFormulaTwo > 0">
                {{getFormulaTwo}}  ({{factor}} = {{(factor * getFormulaTwo).toFixed(2)}})
                <v-icon color="red accent-4" class="buttons-class" v-if="getAverage > 0 && getFormulaTwo > getAverage">call_made</v-icon>
                <v-icon color="green" class="buttons-class" v-if="getAverage > 0 && getFormulaTwo < getAverage">call_received</v-icon>
             </span>
          </v-col>
        </v-row>  

        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            Formuła 3
            <span style="font-size: 12px">205.8 - (0.685 * wiek)</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3">
             <span v-if="getFormulaThree > 0">
                {{getFormulaThree}}  ({{factor}} = {{(factor * getFormulaThree).toFixed(2)}})
                <v-icon color="red accent-4" class="buttons-class" v-if="getAverage > 0 && getFormulaThree > getAverage">call_made</v-icon>
                <v-icon color="green" class="buttons-class" v-if="getAverage > 0 && getFormulaThree < getAverage">call_received</v-icon>
             </span>
          </v-col>
        </v-row>  

        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            Formuła 4
            <span style="font-size: 12px">206.3 - (0.711 * wiek)</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3">
             <span v-if="getFormulaFour > 0">
                {{getFormulaFour}}  ({{factor}} = {{(factor * getFormulaFour).toFixed(2)}})
                <v-icon color="red accent-4" class="buttons-class" v-if="getAverage > 0 && getFormulaFour > getAverage">call_made</v-icon>
                <v-icon color="green" class="buttons-class" v-if="getAverage > 0 && getFormulaFour < getAverage">call_received</v-icon>
             </span>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            Formuła 5
            <span style="font-size: 12px">217 - (0.85 * wiek)</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3">
             <span v-if="getFormulaFive > 0">
                {{getFormulaFive}}  ({{factor}} = {{(factor * getFormulaFive).toFixed(2)}})
                <v-icon color="red accent-4" class="buttons-class" v-if="getAverage > 0 && getFormulaFive > getAverage">call_made</v-icon>
                <v-icon color="green" class="buttons-class" v-if="getAverage > 0 && getFormulaFive < getAverage">call_received</v-icon>
             </span>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            Formuła 6
            <span style="font-size: 12px">207 - (0.7 * wiek)</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3">
             <span v-if="getFormulaSix > 0">
                {{getFormulaSix}}  ({{factor}} = {{(factor * getFormulaSix).toFixed(2)}})
                <v-icon color="red accent-4" class="buttons-class" v-if="getAverage > 0 && getFormulaSix > getAverage">call_made</v-icon>
                <v-icon color="green" class="buttons-class" v-if="getAverage > 0 && getFormulaSix < getAverage">call_received</v-icon>
             </span>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            Formuła 7
            <span style="font-size: 12px">208 - (0.7 * wiek)</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3">
              <span v-if="getFormulaSeven > 0">
                {{getFormulaSeven}}  ({{factor}} = {{(factor * getFormulaSeven).toFixed(2)}})
                <v-icon color="red accent-4" class="buttons-class" v-if="getAverage > 0 && getFormulaSeven > getAverage">call_made</v-icon>
                <v-icon color="green" class="buttons-class" v-if="getAverage > 0 && getFormulaSeven < getAverage">call_received</v-icon>
              </span>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            Formuła 8
            <span style="font-size: 12px">223 - (0.9 * wiek)</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3">
            <span v-if="getFormulaEight > 0">
              {{getFormulaEight}}  ({{factor}} = {{(factor * getFormulaEight).toFixed(2)}})
                <v-icon color="red accent-4" class="buttons-class" v-if="getAverage > 0 && getFormulaEight > getAverage">call_made</v-icon>
                <v-icon color="green" class="buttons-class" v-if="getAverage > 0 && getFormulaEight < getAverage">call_received</v-icon>
            </span>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            Formuła 9
            <span style="font-size: 12px">202 - (0.55 * wiek)</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3">
             <span v-if="getFormulaNine > 0">
                {{getFormulaNine}}  ({{factor}} = {{(factor * getFormulaNine).toFixed(2)}})
                <v-icon color="red accent-4" class="buttons-class" v-if="getAverage > 0 && getFormulaNine > getAverage">call_made</v-icon>
                <v-icon color="green" class="buttons-class" v-if="getAverage > 0 && getFormulaNine < getAverage">call_received</v-icon>
             </span>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            Formuła 10
            <span style="font-size: 12px">206.3 - (0.711 * wiek)</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3">
             <span v-if="getFormulaTen > 0">
                {{getFormulaTen}}  ({{factor}} = {{(factor * getFormulaTen).toFixed(2)}})
                <v-icon color="red accent-4" class="buttons-class" v-if="getAverage > 0 && getFormulaTen > getAverage">call_made</v-icon>
                <v-icon color="green" class="buttons-class" v-if="getAverage > 0 && getFormulaTen < getAverage">call_received</v-icon>
             </span>
          </v-col>
        </v-row>


        <v-row justify="center">
          <v-col cols="6" sm="4" md="3">
            Formuła 11
            <span style="font-size: 12px">211 - (0.64 * wiek)</span>
            :
          </v-col>

           <v-col cols="6" sm="4" md="3">
             <span v-if="getFormulaEleven > 0">
                {{getFormulaEleven}}  ({{factor}} = {{(factor * getFormulaEleven).toFixed(2)}})
                <v-icon color="red accent-4" class="buttons-class" v-if="getAverage > 0 && getFormulaEleven > getAverage">call_made</v-icon>
                <v-icon color="green" class="buttons-class" v-if="getAverage > 0 && getFormulaEleven < getAverage">call_received</v-icon>
             </span>
          </v-col>
        </v-row>


      </v-col>
    </div>

   
</template>

<script>

export default {
  name: 'hr-maxMain',

  components: {
    
  },
  methods: {
    set_session_weight(){
      sessionStorage.setItem('session_weight', this.weight);
    },

    set_session_height(){
      sessionStorage.setItem('session_height', this.height);
    },

    set_session_age(){
      sessionStorage.setItem('session_age', this.age);
    },

    set_session_sex(){
      sessionStorage.setItem('session_sex', this.sex);
    },

    set_session_factor(){
      sessionStorage.setItem('session_factor', this.factor);
    },

  },
  computed: {
    getBMI() {
      if (this.weight != 0 && this.height != 0){
        return  (this.weight / ((this.height / 100) * (this.height / 100))).toFixed(2);
      }
      return "";
    },


    getFormulaOne() {
      if (this.sex != "" && this.age != "" && this.weight != "" && this.height != ""){
        if(this.sex == "K"){
          return (210 - (0.5 * this.age) - (0.022 * this.weight)).toFixed(2);
        } 
        else {
          return (210 - (0.5 * this.age) - (0.022 * this.weight) + 4).toFixed(2);
        }
      }
      return "";
    },

    getFormulaTwo() {
      if (this.age != ""){
        return (220 - this.age).toFixed(2);
      }
      return "";
    },

    getFormulaThree() {
      if (this.age != ""){
        return (205.8 - (0.685 * this.age)).toFixed(2);
      }
      return "";
    },

    getFormulaFour() {
      if (this.age != ""){
        return (206.3 - (0.711 * this.age)).toFixed(2);
      }
      return "";
    },

    getFormulaFive() {
      if (this.age != ""){
        return (217 - (0.85 * this.age)).toFixed(2);
      }
      return "";
    },

    getFormulaSix() {
      if (this.age != ""){
        return (207 - (0.7 * this.age)).toFixed(2);
      }
      return "";
    },

    getFormulaSeven() {
      if (this.age != ""){
        return (208 - (0.7 * this.age)).toFixed(2);
      }
      return "";
    },

    getFormulaEight() {
      if (this.age != "" && this.sex == "K"){
        return (226 - (0.9 * this.age)).toFixed(2);
      }

      if (this.age != "" && this.sex == "M"){
        return (223 - (0.9 * this.age)).toFixed(2);
      }
      return "";
    },

    getFormulaNine() {
      if (this.age != "" && this.sex == "K"){
        return (216 - (1.9 * this.age)).toFixed(2);
      }

      if (this.age != "" && this.sex == "M"){
        return (202 - (0.55 * this.age)).toFixed(2);
      }
      return "";
    },

    getFormulaTen() {
      if (this.age != ""){
        return (206.3 - (0.711 * this.age)).toFixed(2);
      }

      return "";
    },

    getFormulaEleven() {
      if (this.age != ""){
        return (211 - (0.64 * this.age)).toFixed(2);
      }

      return "";
    },

    getAverage() {

      let  sum = 0.0;
      let counter = 0;
      
      if(this.getFormulaOne != ""){
        sum += parseFloat(this.getFormulaOne);
        counter ++;
      }

      if(this.getFormulaTwo != ""){
        sum += parseFloat(this.getFormulaTwo);
        counter ++;
      }

      if(this.getFormulaThree != ""){
        sum += parseFloat(this.getFormulaThree);
        counter ++;
      }

      if(this.getFormulaFour != ""){
        sum += parseFloat(this.getFormulaFour);
        counter ++;
      }

      if(this.getFormulaFive != ""){
        sum += parseFloat(this.getFormulaFive);
        counter ++;
      }

      if(this.getFormulaSix != ""){
        sum += parseFloat(this.getFormulaSix);
        counter ++;
      }

      if(this.getFormulaSeven != ""){
        sum += parseFloat(this.getFormulaSeven);
        counter ++;
      }

      if(this.getFormulaEight != ""){
        sum += parseFloat(this.getFormulaEight);
        counter ++;
      }

      if(this.getFormulaNine != ""){
        sum += parseFloat(this.getFormulaNine);
        counter ++;
      }

      if(this.getFormulaTen != ""){
        sum += parseFloat(this.getFormulaTen);
        counter ++;
      }

      if(this.getFormulaEleven != ""){
        sum += parseFloat(this.getFormulaEleven);
        counter ++;
      }
    
      sessionStorage.setItem('avgHRmax', Math.round(sum/counter).toString());
      return (sum/counter).toFixed(2);
    
    },


  },

  data: () => ({
    items: ['K', 'M'],
    sex: '',
    age: '',
    weight: '',
    height: '',
    factor: 0.85
  }),

  mounted() {
     if (!isNaN(sessionStorage.getItem('session_weight')) && sessionStorage.getItem('session_weight') != null) {
        this.weight = sessionStorage.getItem('session_weight');
    }

    if (!isNaN(sessionStorage.getItem('session_height')) && sessionStorage.getItem('session_height') != null) {
      this.height = sessionStorage.getItem('session_height');
    }

    if (!isNaN(sessionStorage.getItem('session_age')) && sessionStorage.getItem('session_age') != null) {
      this.age = sessionStorage.getItem('session_age');
    }

    if (sessionStorage.getItem('session_sex') != '') {
      this.sex = sessionStorage.getItem('session_sex');
    }

  if (sessionStorage.getItem('session_factor') != null) {
      this.factor = sessionStorage.getItem('session_factor');
    }
   }
};
</script>
