<template>
    <div>
        
      <v-container>


        <v-row justify="center">
          <v-col
            cols="8"
          >
            <v-text-field
              label="tętno maksymalne"
              v-model= "hrMAX"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>


        <v-card
            class="mx-auto"
            max-width="750"
            outlined
        >
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline mb-4">
                        Strefa 1
                        <v-divider></v-divider>
                    </div>
                    <v-list-item-title class="headline mb-1">
                        50% - 60% tętna maksymalnego ({{Math.round(0.5*hrMAX)}} - {{Math.round(0.6*hrMAX)}})
                    </v-list-item-title>
                    <v-list-item-subtitle>Intensywność bardzo niska</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    rounded
                    height="75"
                    width="150"
                    color="green lighten-4"
                ></v-list-item-avatar>
            </v-list-item>
        </v-card>

        <v-card
            class="mx-auto"
            max-width="750"
            outlined
        >
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline mb-4">
                        Strefa 2
                        <v-divider></v-divider>
                    </div>
                    <v-list-item-title class="headline mb-1">
                        60% - 70% tętna maksymalnego ({{Math.round(0.6*hrMAX)}} - {{Math.round(0.7*hrMAX)}})
                    </v-list-item-title>
                    <v-list-item-subtitle>Intensywność lekka</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    rounded
                    height="75"
                    width="150"
                    color="green lighten-2"
                ></v-list-item-avatar>
            </v-list-item>
        </v-card>

        <v-card
            class="mx-auto"
            max-width="750"
            outlined
        >
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline mb-4">
                        Strefa 3
                        <v-divider></v-divider>
                    </div>
                    <v-list-item-title class="headline mb-1">
                        70% - 80% tętna maksymalnego ({{Math.round(0.7*hrMAX)}} - {{Math.round(0.8*hrMAX)}})
                    </v-list-item-title>
                    <v-list-item-subtitle>Intensywność umiarkowana</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    rounded
                    height="75"
                    width="150"
                    color="green"
                ></v-list-item-avatar>
            </v-list-item>
        </v-card>
        <v-card
            class="mx-auto"
            max-width="750"
            outlined
        >
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline mb-4">
                        Strefa 4
                        <v-divider></v-divider>
                    </div>
                    <v-list-item-title class="headline mb-1">
                        80% - 90% tętna maksymalnego ({{Math.round(0.8*hrMAX)}} - {{Math.round(0.9*hrMAX)}})
                    </v-list-item-title>
                    <v-list-item-subtitle>Intensywność intensywna</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    rounded
                    height="75"
                    width="150"
                    color="amber"
                ></v-list-item-avatar>
            </v-list-item>
        </v-card>
        <v-card
            class="mx-auto"
            max-width="750"
            outlined
        >
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline mb-4">
                        Strefa 5
                        <v-divider></v-divider>
                    </div>
                    <v-list-item-title class="headline mb-1">
                        90% - 100% tętna maksymalnego ({{Math.round(0.9*hrMAX)}} - {{Math.round(hrMAX)}})
                    </v-list-item-title>
                    <v-list-item-subtitle>Intensywność maksymalna</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    rounded
                    height="75"
                    width="150"
                    color="deep-orange accent-3"
                ></v-list-item-avatar>
            </v-list-item>
        </v-card>
    </div>

   
</template>

<script>

export default {
  name: 'hr-zones',

  components: {
    
  },
  computed: {
    

  },
  methods: {
      
  },
  mounted() {
      if (!isNaN(sessionStorage.getItem('avgHRmax'))) {
          this.hrMAX = sessionStorage.getItem('avgHRmax');
      }
  },

  data: () => ({
    hrMAX: null ,
  }),
};
</script>
