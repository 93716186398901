<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="4">
        <v-text-field
          label="Nazwa miejscowości"
          v-model="CityName"
          @keypress="fetchWeather"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn
          depressed
          color="primary"
          :loading="checkLoader"
          :disabled="checkLoader"
          @click="runData()"
        >
          Pobierz dane
          <v-icon> mdi-download </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="haveData">
      <v-col cols="4">
        Lokalizacja: {{ weather.name }}, {{ weather.sys.country }}
      </v-col>
      <v-col cols="4">
        Jakość powietrza:
        {{ getAirQualityDescription(airQuality.list[0].main.aqi) }}
      </v-col>
    </v-row>
    <v-row justify="center" v-if="haveData">
      <v-col cols="4">
        Wschód słońca: {{ getTime(weather.sys.sunrise) }}
      </v-col>
      <v-col cols="4"> Zachód słońca: {{ getTime(weather.sys.sunset) }} </v-col>
    </v-row>

    <v-row justify="center" v-if="haveData">
      <v-col cols="8">
        <v-divider />
      </v-col>
    </v-row>
    <a id="jakosc-powietrza"></a>
    <v-row justify="center" v-if="haveData">
      <v-col cols="8">
        <h3>Jakość powietrza:</h3>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="haveData">
      <v-col cols="4">
        PM 2.5: {{ airQuality.list[0].components.pm2_5 }} μg/m3 ({{
          parseFloat((airQuality.list[0].components.pm2_5 / 25) * 100).toFixed(
            2
          )
        }}% normy)
      </v-col>
      <v-col cols="4">
        PM 10: {{ airQuality.list[0].components.pm10 }} μg/m3 ({{
          parseFloat((airQuality.list[0].components.pm10 / 50) * 100).toFixed(
            2
          )
        }}% normy)
      </v-col>
    </v-row>

    <v-row justify="center" v-if="haveData">
      <v-col cols="4"> NO: {{ airQuality.list[0].components.no }} μg/m3</v-col>
      <v-col cols="4">
        NO2: {{ airQuality.list[0].components.no2 }} μg/m3 ({{
          parseFloat((airQuality.list[0].components.no2 / 200) * 100).toFixed(
            2
          )
        }}% normy)</v-col
      >
    </v-row>

    <v-row justify="center" v-if="haveData">
      <v-col cols="4">
        CO: {{ airQuality.list[0].components.co }} μg/m3 ({{
          parseFloat((airQuality.list[0].components.co / 10000) * 100).toFixed(
            2
          )
        }}% normy)</v-col
      >
      <v-col cols="4">
        O3: {{ airQuality.list[0].components.o3 }} μg/m3 ({{
          parseFloat((airQuality.list[0].components.o3 / 180) * 100).toFixed(2)
        }}% normy)</v-col
      >
    </v-row>

    <v-row justify="center" v-if="haveData">
      <v-col cols="4">
        SO2: {{ airQuality.list[0].components.so2 }} μg/m3 ({{
          parseFloat((airQuality.list[0].components.so2 / 125) * 100).toFixed(
            2
          )
        }}% normy)</v-col
      >
      <v-col cols="4">
        NH3: {{ airQuality.list[0].components.nh3 }} μg/m3</v-col
      >
    </v-row>

    <v-row justify="center" v-if="haveData">
      <v-col cols="9">
        <v-divider />
      </v-col>
    </v-row>

    <v-row justify="center" v-if="haveData">
      <v-col cols="9">
        <h3>
          Prognoza pogody:
          <v-btn icon color="blue" right href="#top">
            <v-icon>mdi-arrow-up-bold</v-icon>
          </v-btn>
        </h3>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="haveData">
      <v-col cols="9">
        <v-data-table
          :headers="headersWeather"
          :items="weatherHourly.hourly"
          item-key="dt"
          :items-per-page="12"
          :footer-props="{
            showFirstLastPage: true,
          }"
        >
          <template v-slot:item.dt="{ item }">
            {{ convertDate(item.dt) }}
          </template>
          <template v-slot:item.temp="{ item }">
            {{ item.temp }}&deg;C
          </template>
          <template v-slot:item.feels_like="{ item }">
            <span :style="{ color: getColor(item.feels_like) }">
              {{ item.feels_like }}&deg;C
            </span>
          </template>
          <template v-slot:item.humidity="{ item }">
            {{ item.humidity }}%
          </template>
          <template v-slot:item.pressure="{ item }">
            {{ item.pressure }} hPa
          </template>
          <template v-slot:item.weather="{ item }">
            {{ item.weather[0].description }}</template
          >
          <template v-slot:item.wind_speed="{ item }">
            {{ item.wind_speed }} [m/s] /
            {{ convertSpeed(item.wind_speed) }} [km/h]
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="haveData">
      <v-col cols="9">
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "airConditions",
  components: {},
  data() {
    return {
      api_key: "6415ce72d573f4637100a318484907b7",
      url_base: "https://api.openweathermap.org/data/2.5/",
      CityName: null,
      lon: null,
      lat: null,
      weather: {},
      airQuality: {},
      weatherHourly: {},
      officialCityName: null,
      officialCountryName: null,
      haveData: false,
      loading: false,
      headersWeather: [
        { text: "data", value: "dt", sortable: true },
        { text: "Temperatura", value: "temp", sortable: true },
        {
          text: "Temp. odczuwalna",
          value: "feels_like",
          sortable: true,
        },
        { text: "Wilgotność", value: "humidity", sortable: true },
        { text: "Ciśnienie", value: "pressure", sortable: true },
        { text: "Opis", value: "weather", sortable: true },
        { text: "Prędkość wiatru", value: "wind_speed", sortable: true },
        { text: "Index UV", value: "uvi", sortable: true },
      ],
      refreshCounter: 0,
    };
  },
  created() {
    if (sessionStorage.getItem("session_City") != "") {
      this.CityName = sessionStorage.getItem("session_City");
    }


    if (this.CityName != "" && this.CityName != null) {
      this.getDailyData();
    }
  },
  computed: {
    checkLoader: function () {
      
      if (this.refreshCounter <= 0) {
        return false;
      }
      else {
        return true;
      }
    }
  },

  methods: {
    getAirQualityDescription(_factor) {
      if (_factor == 1) {
        return "Bardzo dobra";
      }
      if (_factor == 2) {
        return "Dobra";
      }
      if (_factor == 3) {
        return "Średnia";
      }
      if (_factor == 4) {
        return "Zła!";
      }
      if (_factor == 5) {
        return "Bardzo zła!!!";
      }

      return "brak danych";
    },
    getColor(_temp) {
      if (_temp < -5) {
        return "blue";
      }
      if (_temp <= 0) {
        return "cyan";
      }
      if (_temp <= 10) {
        return "lime";
      }

      if (_temp <= 20) {
        return "green";
      }

      if (_temp <= 25) {
        return "amber";
      }

      return "red";
    },
    convertDate(_date) {
      var months = [
        "Sty",
        "Lut",
        "Mar",
        "Kwi",
        "Maj",
        "Cze",
        "Lip",
        "Sie",
        "Wrz",
        "Paź",
        "Lis",
        "Gru",
      ];
      var a = new Date(_date * 1000);
      var day = a.getDate();
      var month = months[a.getMonth()];
      var hour = a.getHours();
      var min = a.getMinutes();

      return day + "-" + month + " " + hour + ":" + min + "0";

      //return new Date(_date * 1000).toLocaleString().substring(_start, _stop);
    },
    getTime(_timestamp) {
      var a = new Date(_timestamp * 1000);
      var hour = a.getHours();
      var min = a.getMinutes();
      if (min < 10) {
        return hour + ":0" + min;
      } else {
        return hour + ":" + min;
      }
    },
    convertSpeed(_input) {
      return parseFloat(parseFloat(_input) * 3.6).toFixed(2);
    },

    fetchWeather(e) {
      if (e.key == "Enter") {
        this.runData();
      }
    },

    runData() {
      if (this.CityName == "" || this.CityName == null){
        this.refreshCounter = 0;
        return;
      }

      this.loading = true;
      this.refreshCounter = 3;
      sessionStorage.setItem("session_City", this.CityName);
      this.getDailyData();
    },
    getDailyData() {
      this.refreshCounter = 3;
      
      fetch(
        `${this.url_base}weather?q=${this.CityName}&units=metric&APPID=${this.api_key}&lang=PL`
      )
        .then((res) => {return res.json();})
        .then(this.setResults);
      
    },

    setQualityResults(results) {
      this.airQuality = results;
      this.refreshCounter--;
    },

    setWeatherHourlyResults(results) {
      this.weatherHourly = results;
      this.haveData = true;
      this.refreshCounter--;
    },

    setResults(results) {
      this.weather = results;
      this.lon = this.weather.coord.lon;
      this.lat = this.weather.coord.lat;

      this.officialCityName = this.weather.name;
      this.officialCountryName = this.weather.sys.country;

      //run air quality part
      fetch(
        `${this.url_base}air_pollution?lat=${this.weather.coord.lat}&lon=${this.weather.coord.lon}&units=metric&APPID=${this.api_key}&lang=PL`
      )
        .then((res) => {
          return res.json();
        })
        .then(this.setQualityResults);

      //run weather hourly
      fetch(
        `${this.url_base}onecall?exclude=minutely&lat=${this.weather.coord.lat}&lon=${this.weather.coord.lon}&units=metric&APPID=${this.api_key}&lang=PL`
      )
        .then((res) => {
          return res.json();
        })
        .then(this.setWeatherHourlyResults);

      
      this.saveCoordinates();
      this.refreshCounter--;
    },

    getWeatherAirQuelity() {},

    saveCoordinates() {
      let strAddress = "";
      if (window.location.href.includes("www")) {
        strAddress = "https://www.runisfun.eu/phpFiles/saveCoordinates.php";
      } else {
        strAddress = "https://runisfun.eu/phpFiles/saveCoordinates.php";
      }

      axios
        .post(strAddress, {
          CityName: this.CityName,
          lat: this.lat,
          lon: this.lon,
          pass: "uhR98k3fOKtfds3",
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
</style>
