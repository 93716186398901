<template>
  <div class="contact">
    <ContactForm />
  </div>
</template>

<script>

import ContactForm from '../components/Contact/Contact.vue'

export default {
  name: 'Contact',
  components: {
    ContactForm,
  },
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: 'bieganie jest fajne! - czekamy na wiadomość od Ciebie!'
  }
}
</script>