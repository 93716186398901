<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="8">
          <h2>Test Coopera (test wydolnościowy)</h2>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="8">
          <p>
            Informacje przedstawione poniżej pochodzą z
            <a href="https://pl.wikipedia.org/wiki/Test_Coopera" target="_blink"
              >https://pl.wikipedia.org/wiki/Test_Coopera</a
            >
          </p>
          <br />
          <p>
            <strong
              >"Próba wytrzymałościowa opracowana przez amerykańskiego lekarza
              Kennetha H. Coopera na potrzeby armii USA w 1968 roku, polegająca
              na 12 minutowym nieprzerwanym biegu."</strong
            >
          </p>
          <p>O co chodzi? To bardzo proste!</p>
          <p>
            Jak zawsze zaczynamy od rozgrzewki. Następnie przez
            <b>12 minut</b> biegniemy tak, aby przebiec jak najwięcej. Jeżeli w
            czasie biegu zabraknie Ci siły to postraj się nie zatrzymywać, ale
            przynajmniej maszerować.
            <br />
            Po upływie <b>12 minut</b> musimy zanotować ile kilometrów udało się
            przebiec a potem możemy sprawdzić w tabelach (poniżej) testu Coopera
            w jakiej Jesteśmy formie.
            <br />
            Dodatkowo możesz użyć poniższego kalkulatora, aby szybko i łatwo
            sprawdzić wynik Twojego testu.
          </p>
          <p>
            I to jeszcze nie koniec!
            <br />
            Poniższy kalkulator wyliczy też średnie tempo i prędkość biegu
            podaczas testu, oraz oszacuje prędkość i tempo treningu jako 75%
            osiągniętego wyniku. Ten współczynnik to jedna z "teorii", która
            może podpowiedzieć jak powinniśmy trenować.
          </p>
          <br />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="8">
          <v-card class="mx-auto" max-width="600">
            <v-toolbar flat dense>
              <v-toolbar-title>
                <span class="subheading">Test Coopera, Twój wynik:</span>
              </v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-row class="mb-4" justify="space-between">
                <v-col class="text-left">
                  <v-select
                    :items="items"
                    label="Płeć"
                    v-model="sex"
                    @input="set_session_sex"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row class="mb-4" justify="space-between">
                <v-col class="text-left">
                  <span class="display-3 font-weight-light" v-text="age"></span>
                  <span class="subheading font-weight-light mr-1">wiek</span>
                </v-col>
              </v-row>

              <v-slider
                v-model="age"
                color="blue"
                track-color="blue"
                always-dirty
                min="8"
                max="111"
                @input="set_session_age"
              >
              </v-slider>
            </v-card-text>

            <v-card-text>
              <v-row class="mb-4" justify="space-between">
                <v-col class="text-left">
                  <span
                    class="display-3 font-weight-light"
                    v-text="runDistance"
                  ></span>
                  <span class="subheading font-weight-light mr-1"
                    >dystans w metrach</span
                  >
                </v-col>
              </v-row>

              <v-slider
                v-model="runDistance"
                color="blue"
                track-color="blue"
                always-dirty
                min="500"
                max="3500"
                step="10"
                @input="set_session_runDistance"
              >
              </v-slider>
            </v-card-text>

            <v-card-text>
              <v-row class="mb-4" justify="space-between">
                <v-col class="text-left">
                  <span
                    class="display-2 font-weight-light"
                    v-text="getCooperTestResult"
                  ></span>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row class="mb-4" justify="space-between">
                <v-col class="text-left"> tempo: {{ getPaste }} </v-col>
              </v-row>
              <v-row class="mb-4" justify="space-between">
                <v-col class="text-left"> prędkość: {{ getSpeed }} </v-col>
              </v-row>

              <v-row class="mb-4" justify="space-between">
                <v-col class="text-left"> pułap tlenowy: {{ getOlvl }} </v-col>
              </v-row>

              <v-row class="mb-4" justify="space-between">
                <v-col class="text-left">
                  tempo treningu: {{ getTrainingPaste }}
                </v-col>
              </v-row>
              <v-row class="mb-4" justify="space-between">
                <v-col class="text-left">
                  prędkość treningu: {{ getTrainingSpeed }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <br /><br />
          <p>
            Wyszło słabo? Nie przejmuj się, zapamiętaj rezultat, trenuj dalej i
            zrób ten sam test po kilku tygodniach. Życzę poprawy wyników!
          </p>
          <br />
          <p>
            Poniżej tabele ze szczegółowymi danymi wyników dla
            <strong>testu Coopera</strong>
          </p>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="8">
          <h3>Kobiety:</h3>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="8">
          <v-data-table
            :headers="headers"
            :items="resultsWomen"
            item-key="name"
            class="elevation-1"
            hide-default-footer
            :items-per-page="12"
          ></v-data-table>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="8">
          <h3>Mężczyźni:</h3>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="8">
          <v-data-table
            :headers="headers"
            :items="resultsMen"
            item-key="name"
            class="elevation-1"
            hide-default-footer
            :items-per-page="12"
          ></v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
export default {
  name: "CooperTest",
  components: {},

  metaInfo: {
    //titleTemplate: '%s | test title',
    title: "bieganie jest fajne! - test Coopera",
  },

  mounted() {
    if (sessionStorage.getItem("session_sex") !== null) {
      this.sex = sessionStorage.getItem("session_sex");
    }

    if (!isNaN(sessionStorage.getItem("session_age"))) {
      this.age = sessionStorage.getItem("session_age");
    }

    if (!isNaN(sessionStorage.getItem("session_runDistance"))) {
      this.runDistance = sessionStorage.getItem("session_runDistance");
    }
  },

  methods: {
    set_session_runDistance() {
      sessionStorage.setItem("session_runDistance", this.runDistance);
    },
    set_session_sex() {
      sessionStorage.setItem("session_sex", this.sex);
    },
    set_session_age() {
      if (this.age > 8) {
        sessionStorage.setItem("session_age", this.age);
      }
    },
  },

  computed: {
    getSpeed() {
      if (this.runDistance == null || isNaN(this.runDistance)) {
        return "";
      }
      return parseFloat((this.runDistance / 1000) * 5).toFixed(2) + " km/h";
    },
    getTrainingSpeed() {
      if (this.runDistance == null || isNaN(this.runDistance)) {
        return "";
      }

      return (
        parseFloat(((0.75 * this.runDistance) / 1000) * 5).toFixed(2) + " km/h"
      );
    },
    getPaste() {
      if (this.runDistance == null || isNaN(this.runDistance)) {
        return "";
      }

      let date = new Date(null);
      date.setSeconds(Math.floor((12 * 60) / (this.runDistance / 1000)));
      return date.toISOString().substring(14, 19) + " min/km";
    },
    getOlvl() {
      if (this.runDistance == null || isNaN(this.runDistance)) {
        return "";
      }

      return (
        parseFloat((this.runDistance - 504.9) / 44.73).toFixed(2) + " ml/kg/min"
      );
    },
    getTrainingPaste() {
      if (this.runDistance == null || isNaN(this.runDistance)) {
        return "";
      }

      let date = new Date(null);
      date.setSeconds(
        Math.floor((12 * 60) / ((0.75 * this.runDistance) / 1000))
      );
      return date.toISOString().substring(14, 19) + " min/km";
    },

    getCooperTestResult() {
      if (this.sex == "K") {
        if (this.age == 8) {
          if (this.runDistance >= 2010) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 1670 && this.runDistance <= 2000) {
            return "Dobrze";
          }
          if (this.runDistance >= 1320 && this.runDistance <= 1660) {
            return "Średnio";
          }
          if (this.runDistance >= 990 && this.runDistance <= 1310) {
            return "Źle";
          }
          if (this.runDistance <= 980) {
            return "Bardzo źle";
          }
        }

        if (this.age == 9) {
          if (this.runDistance >= 2120) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 1770 && this.runDistance <= 2110) {
            return "Dobrze";
          }
          if (this.runDistance >= 1400 && this.runDistance <= 1760) {
            return "Średnio";
          }
          if (this.runDistance >= 1050 && this.runDistance <= 1390) {
            return "Źle";
          }
          if (this.runDistance <= 1040) {
            return "Bardzo źle";
          }
        }

        if (this.age == 10) {
          if (this.runDistance >= 2200) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 1860 && this.runDistance <= 2190) {
            return "Dobrze";
          }
          if (this.runDistance >= 1500 && this.runDistance <= 1850) {
            return "Średnio";
          }
          if (this.runDistance >= 1160 && this.runDistance <= 1490) {
            return "Źle";
          }
          if (this.runDistance <= 1150) {
            return "Bardzo źle";
          }
        }
        if (this.age == 11) {
          if (this.runDistance >= 2330) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 1980 && this.runDistance <= 2320) {
            return "Dobrze";
          }
          if (this.runDistance >= 1620 && this.runDistance <= 1970) {
            return "Średnio";
          }
          if (this.runDistance >= 1270 && this.runDistance <= 1610) {
            return "Źle";
          }
          if (this.runDistance <= 1260) {
            return "Bardzo źle";
          }
        }
        if (this.age == 12) {
          if (this.runDistance >= 2370) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2030 && this.runDistance <= 2360) {
            return "Dobrze";
          }
          if (this.runDistance >= 1670 && this.runDistance <= 2020) {
            return "Średnio";
          }
          if (this.runDistance >= 1330 && this.runDistance <= 1660) {
            return "Źle";
          }
          if (this.runDistance <= 1320) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 13 && this.age <= 14) {
          if (this.runDistance > 2000) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 1900 && this.runDistance <= 2000) {
            return "Dobrze";
          }
          if (this.runDistance >= 1600 && this.runDistance <= 1899) {
            return "Średnio";
          }
          if (this.runDistance >= 1500 && this.runDistance <= 1599) {
            return "Źle";
          }
          if (this.runDistance < 1500) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 15 && this.age <= 16) {
          if (this.runDistance > 2100) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2000 && this.runDistance <= 2100) {
            return "Dobrze";
          }
          if (this.runDistance >= 1700 && this.runDistance <= 1999) {
            return "Średnio";
          }
          if (this.runDistance >= 1600 && this.runDistance <= 1699) {
            return "Źle";
          }
          if (this.runDistance < 1600) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 17 && this.age <= 20) {
          if (this.runDistance > 2300) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2100 && this.runDistance <= 2300) {
            return "Dobrze";
          }
          if (this.runDistance >= 1800 && this.runDistance <= 2099) {
            return "Średnio";
          }
          if (this.runDistance >= 1700 && this.runDistance <= 1799) {
            return "Źle";
          }
          if (this.runDistance < 1700) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 20 && this.age <= 29) {
          if (this.runDistance > 2700) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2200 && this.runDistance <= 2700) {
            return "Dobrze";
          }
          if (this.runDistance >= 1800 && this.runDistance <= 2199) {
            return "Średnio";
          }
          if (this.runDistance >= 1500 && this.runDistance <= 1799) {
            return "Źle";
          }
          if (this.runDistance < 1500) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 30 && this.age <= 39) {
          if (this.runDistance > 2500) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2000 && this.runDistance <= 2500) {
            return "Dobrze";
          }
          if (this.runDistance >= 1700 && this.runDistance <= 1999) {
            return "Średnio";
          }
          if (this.runDistance >= 1400 && this.runDistance <= 1699) {
            return "Źle";
          }
          if (this.runDistance < 1400) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 40 && this.age <= 49) {
          if (this.runDistance > 2300) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 1700 && this.runDistance <= 2300) {
            return "Dobrze";
          }
          if (this.runDistance >= 1500 && this.runDistance <= 1899) {
            return "Średnio";
          }
          if (this.runDistance >= 1200 && this.runDistance <= 1499) {
            return "Źle";
          }
          if (this.runDistance < 1100) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 50) {
          if (this.runDistance > 2200) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 1700 && this.runDistance <= 2200) {
            return "Dobrze";
          }
          if (this.runDistance >= 1400 && this.runDistance <= 1699) {
            return "Średnio";
          }
          if (this.runDistance >= 1100 && this.runDistance <= 1399) {
            return "Źle";
          }
          if (this.runDistance < 1100) {
            return "Bardzo źle";
          }
        }
      }

      if (this.sex == "M") {
        if (this.age == 8) {
          if (this.runDistance >= 2190) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 1810 && this.runDistance <= 2180) {
            return "Dobrze";
          }
          if (this.runDistance >= 1420 && this.runDistance <= 1800) {
            return "Średnio";
          }
          if (this.runDistance >= 1050 && this.runDistance <= 1410) {
            return "Źle";
          }
          if (this.runDistance <= 1040) {
            return "Bardzo źle";
          }
        }

        if (this.age == 9) {
          if (this.runDistance >= 2350) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 1950 && this.runDistance <= 2340) {
            return "Dobrze";
          }
          if (this.runDistance >= 1540 && this.runDistance <= 1940) {
            return "Średnio";
          }
          if (this.runDistance >= 1130 && this.runDistance <= 1530) {
            return "Źle";
          }
          if (this.runDistance <= 1120) {
            return "Bardzo źle";
          }
        }

        if (this.age == 10) {
          if (this.runDistance >= 2460) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2070 && this.runDistance <= 2450) {
            return "Dobrze";
          }
          if (this.runDistance >= 1670 && this.runDistance <= 2060) {
            return "Średnio";
          }
          if (this.runDistance >= 1270 && this.runDistance <= 1660) {
            return "Źle";
          }
          if (this.runDistance <= 1260) {
            return "Bardzo źle";
          }
        }
        if (this.age == 11) {
          if (this.runDistance >= 2610) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2200 && this.runDistance <= 2600) {
            return "Dobrze";
          }
          if (this.runDistance >= 1790 && this.runDistance <= 2190) {
            return "Średnio";
          }
          if (this.runDistance >= 1390 && this.runDistance <= 1780) {
            return "Źle";
          }
          if (this.runDistance <= 1380) {
            return "Bardzo źle";
          }
        }
        if (this.age == 12) {
          if (this.runDistance >= 2690) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2290 && this.runDistance <= 2680) {
            return "Dobrze";
          }
          if (this.runDistance >= 1880 && this.runDistance <= 2280) {
            return "Średnio";
          }
          if (this.runDistance >= 1480 && this.runDistance <= 1870) {
            return "Źle";
          }
          if (this.runDistance <= 1470) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 13 && this.age <= 14) {
          if (this.runDistance > 2370) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2400 && this.runDistance <= 2700) {
            return "Dobrze";
          }
          if (this.runDistance >= 2200 && this.runDistance <= 2399) {
            return "Średnio";
          }
          if (this.runDistance >= 2100 && this.runDistance <= 2199) {
            return "Źle";
          }
          if (this.runDistance < 2100) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 15 && this.age <= 16) {
          if (this.runDistance > 2800) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2500 && this.runDistance <= 2800) {
            return "Dobrze";
          }
          if (this.runDistance >= 2300 && this.runDistance <= 2499) {
            return "Średnio";
          }
          if (this.runDistance >= 2200 && this.runDistance <= 2299) {
            return "Źle";
          }
          if (this.runDistance < 2200) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 17 && this.age <= 20) {
          if (this.runDistance > 3000) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2700 && this.runDistance <= 3000) {
            return "Dobrze";
          }
          if (this.runDistance >= 2500 && this.runDistance <= 2699) {
            return "Średnio";
          }
          if (this.runDistance >= 2300 && this.runDistance <= 2499) {
            return "Źle";
          }
          if (this.runDistance < 2300) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 20 && this.age <= 29) {
          if (this.runDistance > 2800) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2400 && this.runDistance <= 2800) {
            return "Dobrze";
          }
          if (this.runDistance >= 2200 && this.runDistance <= 2399) {
            return "Średnio";
          }
          if (this.runDistance >= 1600 && this.runDistance <= 2199) {
            return "Źle";
          }
          if (this.runDistance < 1600) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 30 && this.age <= 39) {
          if (this.runDistance > 2700) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2300 && this.runDistance <= 2700) {
            return "Dobrze";
          }
          if (this.runDistance >= 1900 && this.runDistance <= 2299) {
            return "Średnio";
          }
          if (this.runDistance >= 1500 && this.runDistance <= 1899) {
            return "Źle";
          }
          if (this.runDistance < 1500) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 40 && this.age <= 49) {
          if (this.runDistance > 2500) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2100 && this.runDistance <= 2500) {
            return "Dobrze";
          }
          if (this.runDistance >= 1700 && this.runDistance <= 2099) {
            return "Średnio";
          }
          if (this.runDistance >= 1400 && this.runDistance <= 1699) {
            return "Źle";
          }
          if (this.runDistance < 1400) {
            return "Bardzo źle";
          }
        }
        if (this.age >= 50) {
          if (this.runDistance > 2400) {
            return "Bardzo dobrze";
          }
          if (this.runDistance >= 2000 && this.runDistance <= 2400) {
            return "Dobrze";
          }
          if (this.runDistance >= 1600 && this.runDistance <= 1999) {
            return "Średnio";
          }
          if (this.runDistance >= 1300 && this.runDistance <= 1599) {
            return "Źle";
          }
          if (this.runDistance < 1300) {
            return "Bardzo źle";
          }
        }
      }

      return "";
    },
  },

  data: () => ({
    runDistance: 2000,
    sex: null,
    age: null,
    items: ["K", "M"],

    resultsWomen: [
      {
        wiek: "8",
        distance1: "powyżej 2010m",
        distance2: "1670–2000m",
        distance3: "1320–1660m",
        distance4: "990–1310m",
        distance5: "980-m",
      },
      {
        wiek: "9",
        distance1: "powyżej 2120m",
        distance2: "1770–2110m",
        distance3: "1400–1760m",
        distance4: "1050–1390m",
        distance5: "1040-m",
      },
      {
        wiek: "10",
        distance1: "powyżej 2200m",
        distance2: "1860–2190m",
        distance3: "1500–1850m",
        distance4: "1160–1490m",
        distance5: "1150-m",
      },
      {
        wiek: "11",
        distance1: "powyżej 2330m",
        distance2: "1980–2320m",
        distance3: "1620–1970m",
        distance4: "1270–1610m",
        distance5: "1260-m",
      },
      {
        wiek: "12",
        distance1: "powyżej 2370m",
        distance2: "2030–2360m",
        distance3: "1670–2020m",
        distance4: "1330–1660m",
        distance5: "1320-m",
      },
      {
        wiek: "13-14",
        distance1: "powyżej 2000m",
        distance2: "1900–2000m",
        distance3: "1600–1899m",
        distance4: "1500–1599m",
        distance5: "1500-m",
      },
      {
        wiek: "15-16",
        distance1: "powyżej 2100m",
        distance2: "2000–2100m",
        distance3: "1700–1999m",
        distance4: "1600–1699m",
        distance5: "1600-m",
      },
      {
        wiek: "17-20",
        distance1: "powyżej 2300m",
        distance2: "2100–2300m",
        distance3: "1800–2099m",
        distance4: "1700–1799m",
        distance5: "1700-m",
      },
      {
        wiek: "20-29",
        distance1: "powyżej 2700m",
        distance2: "2200–2700m",
        distance3: "1800–2199m",
        distance4: "1500–1799m",
        distance5: "1500-m",
      },
      {
        wiek: "30-39",
        distance1: "powyżej 2500m",
        distance2: "2000–2500m",
        distance3: "1700–1999m",
        distance4: "1400–1699m",
        distance5: "1400-m",
      },
      {
        wiek: "40-49",
        distance1: "powyżej 2300m",
        distance2: "1900–2300m",
        distance3: "1500–1899m",
        distance4: "1200–1499m",
        distance5: "1200-m",
      },
      {
        wiek: "50+",
        distance1: "powyżej 2200m",
        distance2: "1700–2200m",
        distance3: "1400–1699m",
        distance4: "1100–1399m",
        distance5: "1100-m",
      },
    ],
    resultsMen: [
      {
        wiek: "8",
        distance1: "powyżej 2190m",
        distance2: "1810–2180m",
        distance3: "1420–1800m",
        distance4: "1050–1410m",
        distance5: "1040-m",
      },
      {
        wiek: "9",
        distance1: "powyżej 2350m",
        distance2: "1950–2340m",
        distance3: "1540–1940m	",
        distance4: "1130–1530m",
        distance5: "1120-m",
      },
      {
        wiek: "10",
        distance1: "powyżej 2460m",
        distance2: "2070–2450m",
        distance3: "1670–2060m	",
        distance4: "1270–1660m",
        distance5: "1260-m",
      },
      {
        wiek: "11",
        distance1: "powyżej 2610m",
        distance2: "2200–2600m",
        distance3: "1790–2190m	",
        distance4: "1390–1780m",
        distance5: "1380-m",
      },
      {
        wiek: "12",
        distance1: "powyżej 2690m",
        distance2: "2290–2680m",
        distance3: "1880–2280m	",
        distance4: "1480–1870m",
        distance5: "1470-m",
      },
      {
        wiek: "13-14",
        distance1: "powyżej 2700m",
        distance2: "2400–2700m",
        distance3: "2200–2399m	",
        distance4: "2100–2199m",
        distance5: "2100-m",
      },
      {
        wiek: "15-16",
        distance1: "powyżej 2800m",
        distance2: "2500–2800m",
        distance3: "2300–2499m	",
        distance4: "2200–2299m",
        distance5: "2200-m",
      },
      {
        wiek: "17-20",
        distance1: "powyżej 3000m",
        distance2: "2700–3000m",
        distance3: "2500–2699m	",
        distance4: "2300–2499m",
        distance5: "2300-m",
      },
      {
        wiek: "20-29",
        distance1: "powyżej 2800m",
        distance2: "2400–2800m",
        distance3: "2200–2399m	",
        distance4: "1600–2199m",
        distance5: "1600-m",
      },
      {
        wiek: "30-39",
        distance1: "powyżej 2700m",
        distance2: "2300–2700m",
        distance3: "1900–2299m	",
        distance4: "1500–1899m",
        distance5: "1500-m",
      },
      {
        wiek: "40-49",
        distance1: "powyżej 2500m",
        distance2: "2100–2500m",
        distance3: "1700–2099m	",
        distance4: "1400–1699m",
        distance5: "1400-m",
      },
      {
        wiek: "50+",
        distance1: "powyżej 2400m",
        distance2: "2000–2400m",
        distance3: "1600–1999m	",
        distance4: "1300–1599m",
        distance5: "1300-m",
      },
    ],
    headers: [
      {
        text: "Wiek",
        align: "start",
        sortable: true,
        value: "wiek",
      },
      { text: "Bardzo dobrze", value: "distance1", sortable: false },
      { text: "Dobrze", value: "distance2", sortable: false },
      { text: "Średnio", value: "distance3", sortable: false },
      { text: "Źle", value: "distance4", sortable: false },
      { text: "Bardzo źle", value: "distance5", sortable: false },
    ],
  }),
};
</script>