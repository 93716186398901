<template>
  <div class="RunCalculator">
    <v-container>
      <run_calculator />
    </v-container>
  </div>
</template>

<script>
import run_calculator from "../components/RunCalculator/RunCalculator.vue";
export default {
  name: "RunCalculator",
  components: {
    run_calculator,
  },
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: "bieganie jest fajne! - Kalkulator biegowy, kalkulator tempa",
  },
};
</script>