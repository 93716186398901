<template>
  <div>
    <a id="top"></a>
    <v-container>
      <v-row justify="center">
        <v-col cols="8">
          <h2>Warunki za oknem</h2>
          <p>
            Jeśli zastanawiasz się jak jest za oknem, czy ciepło, czy zimno, czy
            będzie padać, jaka jest jakość powietrza
            - to świetnie trafiłeś!
            <br />
            Wszystkie niezbędne infrormacje w jednym miejscu! Stan auktualny
            plus prognoza na najbliższe godziny/dni.
            <br />
            Wszystkie te infrormacje pozwolą Ci podjąć decyzję kiedy zaplanować
            swój trening: teraz, a może za kilka godzin, a może jutro będzie
            dużo lepsza pogoda?
          </p>
          <br />
          <p>
            Wpisz poniżej nazwę swojej miejscowości i sprawdź aktualne warunki
            oraz prognozę pogody na najbliższe godziny.
          </p>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="8">
          <v-divider />
        </v-col>
      </v-row>

      <airConditions />
      <br />
      <v-divider />
      <p>
        Dane odnośnie pogody i jakości powietrza pochodzą z:
        https://openweathermap.org/
      </p>
    </v-container>
  </div>
</template>

<script>
import airConditions from "../components/airConditions/airConditions.vue";
export default {
  name: "outsideConditiones",
  components: {
    airConditions,
  },
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: "bieganie jest fajne! - Pogoda i jakość powietrza",
  },
};
</script>