<template>
  <div class="about">

   
        W budowie        
  
    

  </div>
</template>

<script>

export default {
  name: 'About',
  components: {
    
  },
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: 'bieganie jest fajne! - o co tu chodzi?',
    
  },

  
}
</script>