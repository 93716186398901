<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="8">
        <h1>Co nowego?</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="8">
        <v-timeline>
          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 21 styczeń 2022 </span>
            </template>
            <div class="py-4">
              <h2>Nowa funkcjonalność!</h2>
              <div>
                Na stronie pojawił się nowy kalkulator!
                <br />
                Teraz można policzyć ile kalorii potrzebujemy do zachowania czynności życiowych a ile jeśli trenujemy etc. 
                <br />
                <router-link to="/kalkulator-BMR-CPM">Kalkulator BMR/CPM</router-link> - zapraszam
                <br />
                <v-img
                  src="https://www.runisfun.eu/_images/calories.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 01 styczeń 2022 </span>
            </template>
            <div class="py-4">
              <h2>Szęśliwego Nowego Roku!</h2>
              <div>
                Z okazji Nowego 2022 Roku ... no cóż - spełnienia wszystkich marzeń! Sami doskonale wiecie co dla Was najlepsze więc tego właśnie życzymy :)
                <br />
                <v-img
                  src="https://www.runisfun.eu/_images/year2022.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 24 grudzień 2021 </span>
            </template>
            <div class="py-4">
              <h2>Wesołych Świąt</h2>
              <div>
                Zdrowych, Wesołych, Spokojnych a może nawet i biegowych Świąt!
                <br />
                <v-img
                  src="https://www.runisfun.eu/_images/christmas.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 25 kwiecień 2021 </span>
            </template>
            <div class="py-4">
              <h2>Jakie są powody biegania?</h2>
              <div>
                <router-link to="/czemu-warto-biegac">Powody, dla których warto biegać</router-link>.
                Postanowiłem się zmierzyć z tak postawionym pytaniem i podać kilka punktów czemu, według mnie, bieganie to fajne zajęcie.
                Zapraszam do zapoznania się z tym co mi wyszło na nowej podstronie.
                <br />
                <v-img
                  src="https://www.runisfun.eu/_images/books.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 23 kwiecień 2021 </span>
            </template>
            <div class="py-4">
              <h2>Nowa funkcjonalność</h2>
              <div>
                <router-link to="/kalkulator-spalania-kalorii">Kalkulator obliczający ilość spalonych kalorii</router-link> w zależności od długości aktywności fizycznej i jej rodzaju.
                Wszystko w przystępnej formie. Zapraszam
                <br />
                <v-img
                  src="https://www.runisfun.eu/_images/run.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 20 kwiecień 2021 </span>
            </template>
            <div class="py-4">
              <h2>Optymalizacja strony, pozycjonowanie, ...</h2>
              <div>
                Od jakiegoś czasu trwają "prace w tle" optymalizujące (bardzo powoli) stronę z punktu widzenia wyszukiwarek internetowych. 
                Pierwsze, pozytywne, rezultaty są już widoczne. Tak więc - Witam Wszystkich, którzy trafili na tę stronę szukając informacji w Internecie.
                Planowane są dalsze prace mające na celu umocnienie pozycji strony RunIsFun! :) 
                <br />
                <v-img
                  src="https://www.runisfun.eu/_images/search.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 11 kwiecień 2021 </span>
            </template>
            <div class="py-4">
              <h2>Poprawki!</h2>
              <div>
                Kilka drobnych poprawek związanych z formatowaniem daty w dziale
                z
                <router-link to="/jakosc-powietrza-pogoda"
                  >prognozą pogody dla biegacza</router-link
                >
                i nie tylko...
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 01 kwiecień 2021 </span>
            </template>
            <div class="py-4">
              <h2>Dałem radę!</h2>
              <div>
                Przygotowania trochę trwały, ale dałem radę! A jeśli ja dałem to
                Ty też dasz!
                <br />
                Wiem, że się chwalę i to nie jest elegancke, ale co mi tam. A
                może nie tyle się chwalę co usprawiedliwiam czemu tutaj się
                niewiele dzieje? Odpowiedź jak jest zostawiam Tobie :)
                <br />
                Teraz czas na nowe pomysły i na dodawanie nowych funkcjonalności
                na <b>RunIsFun</b>.eu
                <v-img
                  src="https://www.runisfun.eu/_images/20210401.jpeg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 08 marzec 2021 </span>
            </template>
            <div class="py-4">
              <h2>Wszystkiego Najlepszego!</h2>
              <div>
                <v-img src="https://www.runisfun.eu/_images/tulips.png"></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 04 marzec 2021 </span>
            </template>
            <div class="py-4">
              <h2>Nowa funkcjonalność!</h2>
              <div>
                Kalkulator wyliczający
                <router-link to="/pulap-tlenowy">pułap tlenowy</router-link>
                skończony!
                <br />
                To kolejne miejsce umożliwiające oszacowanie w jakiej Jesteś
                formie. Zachęcam do korzystania.
                <v-img
                  src="https://www.runisfun.eu/_images/cherries.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 02 marzec 2021 </span>
            </template>
            <div class="py-4">
              <h2>Nowa funkcjonalność!</h2>
              <div>
                To tu to tam pojawiły się wartości pokazujące pułap tlenowy. To
                tak "testowo". Niebawem, jak czas pozwoli, pojawi się nowy dział
                opisujący pułap tlenowy - co to jest, po co, do czego, jak to
                można wyznaczyć (oszacować), jakie są poprawne wartości oraz,
                oczywiście, kalkulator umożliwiający wyliczenie wartości tego
                współczynnika.
                <br />
                A póki co -
                <router-link to="/jakosc-powietrza-pogoda">pogoda</router-link>
                fajna więc trzeba iść pobiegać :)
                <br />
                <strong>Do zobaczenia gdzieś na trasie!</strong>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 26 luty 2021 </span>
            </template>
            <div class="py-4">
              <h2>Nowa funkcjonalność!</h2>
              <div>
                Odpowiednia temperatura, wilgotność, wiatr no i do tego
                odpowiednia jakość powietrza, to wszystko jest potrzebne żeby
                bieganie było jeszcze przyjemniejsze. Jeśli zastanawiasz się jak
                się ubrać, jak to jest "za oknem" i jak będzie w ciągu
                najbliższych godzin to już nie musisz szukać! W dziale
                <router-link to="/jakosc-powietrza-pogoda"
                  >za oknem</router-link
                >
                (może ktoś ma pomysł na lepszą nazwę) znajdziesz wszystkie
                potrzebne informacje. Wystarczy wpisać nazwę miasta i wcisnąć
                Enter.
                <br />
                <v-img src="https://www.runisfun.eu/_images/clouds.png"></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 24 luty 2021 </span>
            </template>
            <div class="py-4">
              <h2>Poprawki!</h2>
              <div>
                Okazało się, że musiałem poprawić pewne funkcjonalności dostępne
                na stronie.
                <router-link to="/kalkulator-biegowy"
                  >Kalkulator biegowy</router-link
                >
                pokazuje już poprawne wartości, dla maratonu i półmaratonu, po
                załadowaniu strony. Pozdrawiam zgłaszającego ten "problem"
                Andrzeja.
                <br />
                <br />
                Wprowadziłem też niewielkie zmiany w dziale
                <router-link to="/kontakt">kontakt</router-link>, do którego
                niezmiennie zachęcam.
                <br />
                <v-img
                  src="https://www.runisfun.eu/_images/programmer.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 20 luty 2021 </span>
            </template>
            <div class="py-4">
              <h2>Nowa funkcjonalność!</h2>
              <div>
                Tym razem przelicznik tempa, prędkości i czasu. Taki prosty
                <router-link to="/kalkulator-biegowy"
                  >kalkulator biegowy</router-link
                >.
                <br />
                Ciekawe jak się spodoba taki, chyba innowacyjny, interfejs
                oparty na suwakach. Wszelkie komentarze proszę kierować
                <router-link to="/kontakt">tutaj</router-link>.
                <br />
                W miarę możliwości czasowych postaram się dodać tam inne
                długości biegu a jak umiejętności pozwolą to może nawet pojawi
                się tam kalkulator, w którym samemu będzie można ustalać
                dystans.
                <br />
                <v-img
                  src="https://www.runisfun.eu/_images/stopwatch.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 19 luty 2021 </span>
            </template>
            <div class="py-4">
              <h2>Koszulki!</h2>
              <div>
                Pierwsze koszulki właśnie dotarły. Kolor biały, logo RunIsFun i
                adres strony internetowej. Są to koszulki techniczne więc
                świetnie nadają się do biegania. Pierwszych kilka kilometrów w
                jednej z nich zrobione i jest dobrze :)
                <br />
                <strong
                  >Mamy kilka koszulek do rozdania. Choć nie jestem fanem
                  rywalizacji to tym razem - Kto pierwszy ten lepszy!</strong
                >
                <br />
                Chętne osoby zapraszamy
                <router-link to="/Kontakt">tutaj</router-link>.
                <br />
                <br />
                <v-img
                  src="https://www.runisfun.eu/_images/t-shirt_ver1.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 19 luty 2021 </span>
            </template>
            <div class="py-4">
              <h2>Usprawnienia i bezpieczeństwo</h2>
              <div>
                Stronę www.RunIsFun.eu można już przeglądać z użyciem https -
                czyli komunikacja jest bezpieczna, połączenie szyfrowane, a
                użytkownicy, zapewne, jeszcze bardziej uśmiechnięci.
                <br />
                Do tego w pamięci przeglądarki (nie mylić z ciasteczkami)
                zapisywane są dane uzupełniane w formularzach kalkulatorów, a to
                oznacza, żeby jesli w jednym miejscu podamy, na przykład, swój
                wiek to w pozostałych kalkulatorach powinien się wypełnić
                automatycznie. Mam nadzieję, że to jeszcze bardziej ułatwi
                korzystanie z dostępnych tutaj funkcjonaności.

                <br />
                <v-img src="https://www.runisfun.eu/_images/safety.jpg"></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 15 luty 2021 </span>
            </template>
            <div class="py-4">
              <h2>Oficjalne logo!</h2>
              <div>
                Po długich konsultacjach zostało wybrane oficjalne logo tego
                projektu. Logo będzie wykorzystywane na oficjalnych koszulkach i
                ... innych elementach niezbędnych podczas biegania,
                spacerowania, spędzania czasu aktywnie.
                <br />
                Jeżeli Jesteś zainteresowany
                <strong>Naszą koszulką</strong> zapraszamy do
                <router-link to="/kontakt">kontaktu</router-link> z Nami i
                zbaczymy co da się zrobić.
                <br />
                <v-img src="https://www.runisfun.eu/_images/logo.png"></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 13 luty 2021 </span>
            </template>
            <div class="py-4">
              <h2>Nowe funkcjonalności!</h2>
              <div>
                Tym razem na stronie pojawił się
                <router-link to="Test-Coopera"
                  >test wydolności - Test Coopera</router-link
                >. Wraz z dokładną tabelą pozwalającą ocenić kondycję jak
                również z bardzo intuicyjnym formularzem, który, bez
                konieczności sprawdzania w tabelach, pokaże w jakiej Jestes
                formie, ale również przeliczy tempo i prędkość biegu podczas
                wykonywania tego testu.
                <br />
                <v-img src="https://www.runisfun.eu/_images/chain.jpg"></v-img>
                <br />
                W tym samym dniu na stronie pokazał się
                <router-link to="/Kalkulator-BMI">kalkulator BMI</router-link>,
                którego zasady działania chyba nie trzeba dodatkowo wyjaśniać,
                nic tylko klikać i sprawdzać!
                <br />
                <v-img src="https://www.runisfun.eu/_images/scale.jpg"></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 12 luty 2021 </span>
            </template>
            <div class="py-4">
              <h2>Formularz kontaktowy</h2>
              <div>
                Dodany został formularz kontaktowy na wypadek gdyby ktoś chciał
                do Nas napisać. Oczywiście każdy Państwa głos, pomysł, sugestia
                są bardoz mile widziane i każdy zostanie dogłębnie
                przeanalizowany i w miarę możliwości dodany na stronę.

                <br />
                <v-img
                  src="https://www.runisfun.eu/_images/contact.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> 10 luty 2021 </span>
            </template>
            <div class="py-4">
              <h2>Pierwsze funkcjonalności</h2>
              <div>
                Uruchomienie podstawowych kalkulatorów bo wyznaczania
                <router-link to="/kalkulator-tetna-maksymalnego"
                  >tętna maksymalnego</router-link
                >, <router-link to="/strefy-tetna">stref tętna</router-link> czy
                korzystającego z
                <router-link to="/Metoda-Karvonena"
                  >metody Karvonena</router-link
                >. Wszystko to po to żeby móc na kilka sposobów, w jednym
                miejscu, policzyć, a raczej oszacować, przy jakim poziomie tętna
                powinniśmy ćwiczyć, jakie jest dla Nas niebezpieczne, jakie
                wzmacnia Naszą wytrzymałość, itd.

                <br />
                <v-img
                  src="https://www.runisfun.eu/_images/heart-rate.jpg"
                ></v-img>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item small>
            <template v-slot:opposite>
              <span> Styczeń 2021 </span>
            </template>
            <div class="py-4">
              <h2>Pomysł</h2>
              <div>
                Wtedy to dwa pomysły: na naukę Vue i na stworzenie narzędzia
                wspomagającego naukę biegania połączyły się w jedno pod nazwą
                RunIsFun.eu

                <br />
                <v-img src="https://www.runisfun.eu/_images/idea.png"></v-img>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "News",
  components: {},
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: "bieganie jest fajne! - Aktualności i nowości na stronie",
  },
};
</script>
