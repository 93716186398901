<template>
  <div>
    <!-- 5km -->
    <a id="5km"></a>
    <v-card class="mx-auto" max-width="600">
      <v-toolbar flat dense>
        <v-toolbar-title>
          <span class="subheading">Wybierz czas aktywności:</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row class="mb-4" justify="space-between">
          <v-col class="text-left">
            <span
              class="display-3 font-weight-light"
              v-text="time_5k_time"
            ></span>
            <span class="subheading font-weight-light mr-1">czas [hh:mm]</span>
          </v-col>
        </v-row>

        <v-slider
          v-model="time_5k_sec"
          track-color="grey"
          always-dirty
          min="600"
          max="10800"
          step="60"
          @input="time_change_5k"
        >
        </v-slider>
      </v-card-text>
    </v-card>

    <br />
    <v-divider />
    <br />

    <v-row justify="center">
      <v-col cols="10">
        <v-data-table
          :headers="headers"
          :items="CaloriesTable"
          item-key="type"
          class="elevation-1"
          hide-default-footer
          :items-per-page="16"
        ></v-data-table>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  name: "CaloriesCalculator",
  components: {},

  mounted() {
    this.time_change_5k();
    this.convertTime5kToTime();
  },

  methods: {
    convertTime5kToTime() {
      let date = new Date(null);
      date.setSeconds(this.time_5k_sec);
      this.time_5k_time = date.toISOString().substring(11, 16);
    },

    calculateCalories() {
      let i = 0;

      for (i = 0; i < this.CaloriesTable.length; i++) {
        this.CaloriesTable[i].calculatedCalories = Math.round(
          (this.time_5k_sec / 3600) * this.CaloriesTable[i].calories
        );

        this.CaloriesTable[i].distance = parseFloat(
          (this.time_5k_sec / 3600) * this.CaloriesTable[i].speed
        ).toFixed(2);
      }
    },

    time_change_5k() {
      this.convertTime5kToTime();

      this.calculateCalories();
    },
  },

  computed: {},

  data: () => ({
    time_5k_sec: 1800, //in sec
    time_5k_time: "00:30",

    headers: [
      { text: "Rodzaj aktywności", value: "type", sortable: true },
      { text: "Kalorie spalane w godzinę", value: "calories", sortable: true },
      { text: "Spalone kalorie", value: "calculatedCalories", sortable: true },
      { text: "Przebyty dystans [km]", value: "distance", sortable: true },
    ],

    CaloriesTable: [
      {
        type: "Spacer",
        calories: 245,
        calculatedCalories: null,
        distance: null,
        speed: 5,
      },
      {
        type: "Spacer szybki 6 km/h",
        calories: 315,
        calculatedCalories: null,
        distance: null,
        speed: 6,
      },
      {
        type: "Bieganie 6,5 km/h",
        calories: 420,
        calculatedCalories: null,
        distance: null,
        speed: 6.5,
      },
      {
        type: "Bieganie 7 km/h",
        calories: 539,
        calculatedCalories: null,
        distance: null,
        speed: 7,
      },
      {
        type: "Bieganie 8 km/h",
        calories: 581,
        calculatedCalories: null,
        distance: null,
        speed: 8,
      },
      {
        type: "Bieganie 9 km/h",
        calories: 672,
        calculatedCalories: null,
        distance: null,
        speed: 9,
      },
      {
        type: "Bieganie 9,5 km/h",
        calories: 686,
        calculatedCalories: null,
        distance: null,
        speed: 9.5,
      },
      {
        type: "Bieganie 10 km/h",
        calories: 735,
        calculatedCalories: null,
        distance: null,
        speed: 10,
      },
      {
        type: "Bieganie 11 km/h",
        calories: 770,
        calculatedCalories: null,
        distance: null,
        speed: 11,
      },
      {
        type: "Bieganie 12 km/h",
        calories: 826,
        calculatedCalories: null,
        distance: null,
        speed: 12,
      },
      {
        type: "Bieganie 13 km/h",
        calories: 938,
        calculatedCalories: null,
        distance: null,
        speed: 13,
      },
      {
        type: "Bieganie 14 km/h",
        calories: 1001,
        calculatedCalories: null,
        distance: null,
        speed: 14,
      },
      {
        type: "Bieganie 15 km/h",
        calories: 1071,
        calculatedCalories: null,
        distance: null,
        speed: 15,
      },
      {
        type: "Bieganie 16 km/h",
        calories: 1134,
        calculatedCalories: null,
        distance: null,
        speed: 16,
      },
      {
        type: "Bieganie 17 km/h",
        calories: 1204,
        calculatedCalories: null,
        distance: null,
        speed: 17,
      },
      {
        type: "Bieganie 18 km/h",
        calories: 1267,
        calculatedCalories: null,
        distance: null,
        speed: 18,
      },
    ],
  }),
};
</script>