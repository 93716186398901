import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta'

import 'material-design-icons-iconfont/dist/material-design-icons.css' 
import VueGtagPlugin from 'vue-gtag';

Vue.use(VueGtagPlugin, {
  config: { id: "G-64THG0L1Y6" }
});

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  iconfont: 'mdi',
  render: h => h(App)
}).$mount('#app')

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})