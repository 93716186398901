<template>
  <div>
    <v-container>
          <v-row justify="center">
            <v-col
              cols="8"
            >
              <h2>Strefy tętna</h2>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col
              cols="8"
            >
              <p>Poniżej znajdują się informacje o strefach (zakresach tętna), jeżeli podasz swoje tętno maksymalne, 
                  które możesz oszacować tutaj (<router-link to="/kalkulator-tetna-maksymalnego">kalkulator tętna maksymalnego</router-link>), 
                  podane zostną "dokładne" wartości tętna przypisane do konkretnych intensywności treningu. </p>
                  <br/>
                  Zachęcam też podejrzeć strefy tętna wyliczone <router-link to="/Metoda-Karvonena"> metodą Karvonena</router-link>
            </v-col>
          </v-row>
    </v-container>

    <hr_zones/>
  </div>
</template>

<script>
import hr_zones from '../components/hrZones/hrZones.vue'
export default {
  name: 'hrZones',
  components: {
    hr_zones,
  },
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: 'bieganie jest fajne! - strefy tętna, idealne tętno do treningu'
  }
}
</script>