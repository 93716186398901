<template>
  <v-container>
    <v-row justify="center">
      <v-col
        cols="8"
      >
        <h1>Błąd 404, strona nie została odnaleziona!</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        cols="6"
      >
        <p>Coś chyba poszło nie tak... </p>
        <p>Zapraszamy do ponownego wybrania jednej z opcji z menu strony.</p>
      </v-col>

      <v-col
        cols="6"
      >
        <v-img
          src="https://www.runisfun.eu/_images/404error.jpg"
        ></v-img>
      </v-col>
    </v-row>

    

    <v-row justify="center">
      <v-col
        cols="12"
      >
        <v-img
          src="https://www.runisfun.eu/_images/logo.png"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Error404',
  components: {
    
  },
  metaInfo: {
      title: 'Bieganie jest fajne, ale adres takiej podstrony nie ma',
      
  },
}
</script>