<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="8">
        <h1>Bieganie jest fajne!</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="8">
        <h2>Dzień dobry :)</h2>
        <p>
          Mam taki pomysł żeby stworzyć stronę dla miłośników biegania. Może to
          będzie bardziej aplikacja? Kto to wie :)
        </p>
        <p>
          Sukcesywnie będę dorzucał tutaj nowe funkcjonalności tak więc zachęcam
          do regularnego zaglądania na tę stronę.
        </p>
        <p>
          Jeśli masz jakiś pomysł co można tutaj dodać to zachęcam do
          <router-link to="/kontakt">kontaktu</router-link>
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="8">
        <p>
          <strong
            >Wszystkie informacje na temat nowych funkcjonalności, zmian, etc.
            znajdują się w dziale
            <router-link to="/Aktualnosci">aktualności</router-link></strong
          >
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">
        <v-img src="https://www.runisfun.eu/_images/logo.png"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: "Bieganie jest fajne!",
  },
};
</script>
