<template>
  <div>
    <v-row justify="center">
      <v-col cols="3" sm="4" md="3">
        <v-select
          :items="items"
          label="Płeć"
          v-model="sex"
          @input="set_session_sex"
        ></v-select>
      </v-col>

      <v-col cols="3" sm="4" md="3">
        <v-text-field
          label="Wiek"
          v-model="age"
          @input="set_session_age"
        ></v-text-field>
      </v-col>

      <v-col cols="3" sm="4" md="3">
        <v-text-field
          label="Waga"
          v-model="weight"
          @input="set_session_weight"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="3">
        <v-text-field
          label="Tętno maksymane"
          v-model="hr_max"
          @input="set_session_hrMax"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Tętno spoczynkowe"
          v-model="hr_rest"
          @input="set_session_hrRest"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Dystans (w metrach) testu Coopera"
          v-model="CooperDistance"
          @input="set_session_CooperDistance"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-divider />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6"> Wynik w oparciu o test Coopera </v-col>
      <v-col cols="4"> {{ getBasedOnCooperResult }} </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6">Wynik w oparciu o puls maksymalny i spoczynkowy</v-col>
      <v-col cols="4"> {{ getBasedOnHR }} </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6"> Wynik w oparciu o wiek, wagę, puls </v-col>
      <v-col cols="4"> {{ getBasedOnAgeWeightHR }} </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-divider />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <h3>Kobiety (ml/kg/min):</h3>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <v-data-table
          :headers="headers"
          :items="resultsWomen"
          item-key="name"
          class="elevation-1"
          hide-default-footer
          :items-per-page="4"
        ></v-data-table>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <h3>Mężczyźni (ml/kg/min):</h3>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <v-data-table
          :headers="headers"
          :items="resultsMen"
          item-key="name"
          class="elevation-1"
          hide-default-footer
          :items-per-page="5"
        ></v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "OxyLevel",
  mounted() {
    if (
      !isNaN(sessionStorage.getItem("session_age")) &&
      sessionStorage.getItem("session_age") != null
    ) {
      this.age = sessionStorage.getItem("session_age");
    }

    if (
      !isNaN(sessionStorage.getItem("session_weight")) &&
      sessionStorage.getItem("session_weight") != null
    ) {
      this.weight = sessionStorage.getItem("session_weight");
    }

    if (sessionStorage.getItem("session_sex") != "") {
      this.sex = sessionStorage.getItem("session_sex");
    }

    if (!isNaN(sessionStorage.getItem("avgHRmax"))) {
      this.hr_max = sessionStorage.getItem("avgHRmax");
    }

    if (!isNaN(sessionStorage.getItem("hrREST"))) {
      this.hr_rest = sessionStorage.getItem("hrREST");
    }

    if (!isNaN(sessionStorage.getItem("session_runDistance"))) {
      this.CooperDistance = sessionStorage.getItem("session_runDistance");
    }
  },

  methods: {
    set_session_sex() {
      sessionStorage.setItem("session_sex", this.sex);
    },
    set_session_age() {
      if (this.age > 0) {
        sessionStorage.setItem("session_age", this.age);
      }
    },
    set_session_weight() {
      sessionStorage.setItem("session_weight", this.weight);
    },
    set_session_hrMax() {
      sessionStorage.setItem("avgHRmax", this.hr_max);
    },
    set_session_hrRest() {
      sessionStorage.setItem("hrREST", this.hr_rest);
    },
    set_session_CooperDistance() {
      sessionStorage.setItem("session_runDistance", this.CooperDistance);
    },
  },

  components: {},

  computed: {
    getBasedOnCooperResult() {
      if (this.CooperDistance == "" || isNaN(this.CooperDistance) || this.CooperDistance == null) {
        return "";
      }

      return (
        parseFloat((this.CooperDistance - 504.9) / 44.73).toFixed(2) +
        " ml/kg/min"
      );
    },
    getBasedOnHR() {
      if (this.hr_rest > 0 && this.hr_max > 0) {
        return (
          parseFloat(15 * (this.hr_max / this.hr_rest)).toFixed(2) +
          " ml/kg/min"
        );
      }
      return "";
    },

    getBasedOnAgeWeightHR() {
      if (this.hr_rest > 0 && this.age > 0 && this.weight) {
        return (
          parseFloat(
            3.542 -
              (0.014 * this.age + 0.015 * this.weight - 0.011 * this.hr_rest)
          ).toFixed(2) + " l/min"
        );
      }
      return "";
    },
  },

  data: () => ({
    items: ["K", "M"],
    sex: null,
    age: null,
    weight: null,
    hr_max: null,
    hr_rest: null,
    CooperDistance: null,

    resultsWomen: [
      {
        age: "20-29",
        score1: "poniżej 29",
        score2: "29-34",
        score3: "35-43",
        score4: "44-48",
        score5: "powyżej 48",
      },

      {
        age: "30-39",
        score1: "poniżej 28",
        score2: "28-33",
        score3: "34-41",
        score4: "42-47",
        score5: "powyżej 47",
      },

      {
        age: "40-49",
        score1: "poniżej 26",
        score2: "26-31",
        score3: "32-40",
        score4: "41-45",
        score5: "powyżej 45",
      },

      {
        age: "50-65",
        score1: "poniżej 22",
        score2: "22-28",
        score3: "29-36",
        score4: "37-41",
        score5: "powyżej 41",
      },
    ],

    resultsMen: [
      {
        age: "20-29",
        score1: "poniżej 39",
        score2: "39-43",
        score3: "44-51",
        score4: "52-56",
        score5: "powyżej 56",
      },

      {
        age: "30-39",
        score1: "poniżej 35",
        score2: "35-39",
        score3: "40-47",
        score4: "48-51",
        score5: "powyżej 51",
      },

      {
        age: "40-49",
        score1: "poniżej 31",
        score2: "31-35",
        score3: "36-43",
        score4: "44-47",
        score5: "powyżej 47",
      },

      {
        age: "50-59",
        score1: "poniżej 26",
        score2: "26-31",
        score3: "32-39",
        score4: "40-43",
        score5: "powyżej 43",
      },

      {
        age: "60-69",
        score1: "poniżej 22",
        score2: "22-26",
        score3: "27-35",
        score4: "36-39",
        score5: "powyżej 39",
      },
    ],

    headers: [
      { text: "Wiek", value: "age", sortable: false },
      { text: "Bardzo mała", value: "score1", sortable: false },
      { text: "Mała", value: "score2", sortable: false },
      { text: "Przeciętna", value: "score3", sortable: false },
      { text: "Duża", value: "score4", sortable: false },
      { text: "Bardzo duża", value: "score5", sortable: false },
    ],
  }),
};
</script>
