<template>
  <div class="BMICalculator">
    <v-container>
      <v-row justify="center">
        <v-col cols="8">
          <h2>Kalkulator spalania kalorii</h2>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="8">
          <p>
            Oczywiście są to tylko obliczenia szacunkowe, ale jako referencja powinny być wystarczająco dobre :)
          </p>
        </v-col>

      </v-row>
    </v-container>

    <Calories_Calculator />
    
  </div>
</template>

<script>
import Calories_Calculator from '../components/CaloriesCalculator/CaloriesCalculator.vue'

export default {
  name: "CaloriesCalculator",
  components: {
    Calories_Calculator
  },
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: "bieganie jest fajne! - Kalkulator spalania kalorii",
  },
};
</script>