<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="4">
          <v-text-field
            label="tętno maksymalne"
            v-model="hrMAX"
            @input="sethrMAX"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            label="tętno spoczynkowe"
            v-model="hrREST"
            @input="sethrREST"
          ></v-text-field>
        </v-col>
      </v-row>
      
    </v-container>

    <v-card class="mx-auto" max-width="750" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            Strefa 1
            <v-divider></v-divider>
          </div>
          <v-list-item-title class="headline mb-1">
            50% - 60% tętna maksymalnego ({{ calculateZone(0.5) }} -
            {{ calculateZone(0.6) }})
          </v-list-item-title>
          <v-list-item-subtitle>Intensywność bardzo niska</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar
          rounded
          height="75"
          width="150"
          color="green lighten-4"
        ></v-list-item-avatar>
      </v-list-item>
    </v-card>

    <v-card class="mx-auto" max-width="750" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            Strefa 2
            <v-divider></v-divider>
          </div>
          <v-list-item-title class="headline mb-1">
            60% - 70% tętna maksymalnego ({{ calculateZone(0.6) }} -
            {{ calculateZone(0.7) }})
          </v-list-item-title>
          <v-list-item-subtitle>Intensywność lekka</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar
          rounded
          height="75"
          width="150"
          color="green lighten-2"
        ></v-list-item-avatar>
      </v-list-item>
    </v-card>

    <v-card class="mx-auto" max-width="750" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            Strefa 3
            <v-divider></v-divider>
          </div>
          <v-list-item-title class="headline mb-1">
            70% - 80% tętna maksymalnego ({{ calculateZone(0.7) }} -
            {{ calculateZone(0.8) }})
          </v-list-item-title>
          <v-list-item-subtitle>Intensywność umiarkowana</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar
          rounded
          height="75"
          width="150"
          color="green"
        ></v-list-item-avatar>
      </v-list-item>
    </v-card>
    <v-card class="mx-auto" max-width="750" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            Strefa 4
            <v-divider></v-divider>
          </div>
          <v-list-item-title class="headline mb-1">
            80% - 90% tętna maksymalnego ({{ calculateZone(0.8) }} -
            {{ calculateZone(0.9) }})
          </v-list-item-title>
          <v-list-item-subtitle>Intensywność intensywna</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar
          rounded
          height="75"
          width="150"
          color="amber"
        ></v-list-item-avatar>
      </v-list-item>
    </v-card>
    <v-card class="mx-auto" max-width="750" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            Strefa 5
            <v-divider></v-divider>
          </div>
          <v-list-item-title class="headline mb-1">
            90% - 100% tętna maksymalnego ({{ calculateZone(0.9) }} -
            {{ calculateZone(1) }})
          </v-list-item-title>
          <v-list-item-subtitle>Intensywność maksymalna</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar
          rounded
          height="75"
          width="150"
          color="deep-orange accent-3"
        ></v-list-item-avatar>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "KarvonenMethod",

  components: {},
  computed: {},

  methods: {
    sethrREST() {
      sessionStorage.setItem("hrREST", this.hrREST);
    },

    sethrMAX() {
      sessionStorage.setItem("avgHRmax", this.hrMAX);
    },

    calculateZone(factor) {
      if (this.hrREST > 0 && this.hrMAX > 0) {
        return Math.round(
          factor * (this.hrMAX - this.hrREST) + parseInt(this.hrREST)
        );
      }
      return 0;
    },

    getOxygenLvl() {
      if (this.hrREST > 0 && this.hrMAX > 0) {
        return (
          parseFloat(15 * (this.hrMAX / this.hrREST)).toFixed(2) + " ml/kg/min"
        );
      }
      return "";
    },
  },
  mounted() {
    if (!isNaN(sessionStorage.getItem("avgHRmax"))) {
      this.hrMAX = sessionStorage.getItem("avgHRmax");
    }

    if (!isNaN(sessionStorage.getItem("hrREST"))) {
      this.hrREST = sessionStorage.getItem("hrREST");
    }
  },

  data: () => ({
    hrMAX: null,
    hrREST: null,
  }),
};
</script>
