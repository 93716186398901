<template>
  <div>
    <v-row justify="center">
      <v-col cols="4">
        <v-select
          :items="items"
          label="Płeć"
          v-model="sex"
          @input="set_session_sex"
        ></v-select>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Wiek"
          v-model="age"
          @input="set_session_age"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="4">
        <v-text-field
          label="Waga"
          v-model="weight"
          @input="set_session_weight"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Wzrost"
          v-model="height"
          @input="set_session_height"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="8">
        <v-select
          :items="itemsActivity"
          label="Rodzaj aktywności fizycznej"
          v-model="activityType"
        ></v-select>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <v-divider />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8"> <strong>BMR:</strong> </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="4"> Metoda Harrisa-Benedicta </v-col>
      <v-col cols="4"> {{ getHBResult }} </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="4"> Metoda Miffin-St Jeor</v-col>
      <v-col cols="4"> {{ getMSJResult }} </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <v-divider />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="8"> <strong>CPM:</strong> </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="4"> Metoda Harrisa-Benedicta </v-col>
      <v-col cols="4"> {{ getHBResult * activityType}} </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="4"> Metoda Miffin-St Jeor</v-col>
      <v-col cols="4"> {{ getMSJResult * activityType}} </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name: "BMR_CPM",
  mounted() {
    if (
      !isNaN(sessionStorage.getItem("session_age")) &&
      sessionStorage.getItem("session_age") != null
    ) {
      this.age = sessionStorage.getItem("session_age");
    }

    if (
      !isNaN(sessionStorage.getItem("session_weight")) &&
      sessionStorage.getItem("session_weight") != null
    ) {
      this.weight = sessionStorage.getItem("session_weight");
    }

    if (sessionStorage.getItem("session_sex") != "") {
      this.sex = sessionStorage.getItem("session_sex");
    }

    if (sessionStorage.getItem("session_height") != "") {
      this.height = sessionStorage.getItem("session_height");
    }
  },

  methods: {
    set_session_sex() {
      sessionStorage.setItem("session_sex", this.sex);
    },
    set_session_age() {
      if (this.age > 0) {
        sessionStorage.setItem("session_age", this.age);
      }
    },
    set_session_weight() {
      sessionStorage.setItem("session_weight", this.weight);
    },
    set_session_height() {
      sessionStorage.setItem("session_height", this.height);
    },

  },

  components: {},

  computed: {
    getHBResult() {
      if (this.sex == "" || this.sex == null) {
        return "";
      }

      if (this.weight == "" || this.weight == null) {
        return "";
      }

      if (this.height == "" || this.height == null) {
        return "";
      }

      if (this.age == "" || this.age == null) {
        return "";
      }

      if (this.sex == "K") {
        return parseFloat(
          655 + 9.6 * this.weight + 1.8 * this.height - 4.7 * this.age
        ).toFixed(0);
      }

      if (this.sex == "M") {
        return parseFloat(
          66 + 13.7 * this.weight + 5 * this.height - 6.76 * this.age
        ).toFixed(0);
      }

      return "";
    },

    getMSJResult() {
      if (this.sex == "" || this.sex == null) {
        return "";
      }

      if (this.weight == "" || this.weight == null) {
        return "";
      }

      if (this.height == "" || this.height == null) {
        return "";
      }

      if (this.age == "" || this.age == null) {
        return "";
      }

      if (this.sex == "K") {
        return parseFloat(
          (9.99 * this.weight) + (6.25 * this.height) - (4.92 * this.age) - 161
        ).toFixed(0);
      }

      if (this.sex == "M") {
        return parseFloat(
          (9.99 * this.weight) + (6.25 * this.height) - (4.92 * this.age) + 5
        ).toFixed(0);
      }

      return "";
    },
  },

  data: () => ({
    items: ["K", "M"],
    itemsActivity: [
      {
        text: "brak aktywności: praca siedząca (x1.2)",
        value: 1.2
      },
      {
        text: "niska aktywność: praca siedząca plus 1-2 treningi w tygodniu (x1.4)",
        value: 1.4
      },
      {
        text: "średnia aktywność: praca siedząca plus 3-4 treningi w tygodniu (x1.6)",
        value: 1.6
      },
      {
        text: "wysoka aktywność: praca fizyczna plus 3-4 treningi w tygodniu (x1.8)",
        value: 1.8
      },
      {
        text: "bardzo wysoka aktywność: zawodowi sportowcy, osoby codziennie trenujące (x2.1)",
        value: 2.1
      },
    ],
    sex: null,
    age: null,
    weight: null,
    height: null,
    activityType: null,
  }),
};
</script>
