<template>
  <div>
    <v-col cols="12">
      <v-row justify="center">
        <v-col cols="6" sm="4" md="3">
          <v-text-field
            label="Waga [kg]"
            v-model="weight"
            @input="set_session_weight"
          ></v-text-field>
        </v-col>

        <v-col cols="6" sm="4" md="3">
          <v-text-field
            label="Wzrost [cm]"
            v-model="height"
            @input="set_session_height"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-row justify="center">
        <v-col cols="4" sm="4" md="3" style="background: #4fc3f7; color: white">
          BMI
        </v-col>
        <v-col cols="4" sm="4" md="3" style="background: #4fc3f7; color: white">
          {{ getBMI }}
          <v-chip
            v-if="getBMI < 18.5 && getBMI > 0"
            class="ma-2"
            color="amber"
            text-color="white"
          >
            <v-icon color="orange darken-4" class="buttons-class"
              >call_received</v-icon
            >
            {{ getBMIDescription() }}
          </v-chip>

          <v-chip
            v-if="getBMI >= 18.5 && getBMI < 25"
            class="ma-2"
            color="green"
            text-color="white"
          >
            <v-icon color="lime " class="buttons-class">check</v-icon> 
            {{ getBMIDescription() }}
          </v-chip>

          <v-chip
            v-if="getBMI >= 25"
            class="ma-2"
            color="amber"
            text-color="white"
          >
            <v-icon color="orange darken-4" class="buttons-class"
              >call_made</v-icon
            >
            {{ getBMIDescription() }}  
          </v-chip>
        </v-col>
      </v-row>
    </v-col>

    <br />
    <br />

    <v-row justify="center">
      <v-col cols="6">
        <v-data-table
          :headers="tableBMIHeaders"
          :items="tableBMI"
          class="elevation-1"
          hide-default-footer
          :items-per-page="12"
        ></v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "bmi-calculator",

  components: {},
  computed: {
    getBMI() {
      if (this.weight != 0 && this.height != 0) {
        return (
          this.weight /
          ((this.height / 100) * (this.height / 100))
        ).toFixed(2);
      }
      return "";
    },
  },

  methods: {
    set_session_weight() {
      sessionStorage.setItem("session_weight", this.weight);
    },

    set_session_height() {
      sessionStorage.setItem("session_height", this.height);
    },

    getBMIDescription() {
      let BMI = null;

      if (this.weight != 0 && this.height != 0) {
        BMI = (
          this.weight /
          ((this.height / 100) * (this.height / 100))
        ).toFixed(2);

        if (BMI < 16) {
          return "wygłodzenie";
        }

        if (BMI >= 16 && BMI < 17) {
          return "wychudzenie";
        }

        if (BMI >= 17 && BMI < 18.5) {
          return "niedowaga";
        }

        if (BMI >= 18.5 && BMI < 25) {
          return "pożądana masa ciała";
        }

        if (BMI >= 25 && BMI < 30) {
          return "nadwaga";
        }

        if (BMI >= 30 && BMI < 35) {
          return "otyłość I stopnia";
        }

        if (BMI >= 35 && BMI < 40) {
          return "otyłość II stopnia (duża)";
        }

        if (BMI >= 40) {
          return "otyłość III stopnia (chorobliwa)";
        }
      }

      return "";
    },
  },

  mounted() {
    if (
      !isNaN(sessionStorage.getItem("session_weight")) &&
      sessionStorage.getItem("session_weight") != null
    ) {
      this.weight = sessionStorage.getItem("session_weight");
    }

    if (
      !isNaN(sessionStorage.getItem("session_height")) &&
      sessionStorage.getItem("session_height") != null
    ) {
      this.height = sessionStorage.getItem("session_height");
    }
  },

  data: () => ({
    weight: "",
    height: "",
    tableBMI: [
      {
        category: "wygłodzenie",
        BMI: "poniżej 16,0",
      },

      {
        category: "wychudzenie",
        BMI: "16,0 - 16,99",
      },

      {
        category: "niedowaga",
        BMI: "17,0 - 18,49",
      },

      {
        category: "pożądana masa ciała",
        BMI: "18,5 - 24,99",
      },

      {
        category: "nadwaga",
        BMI: "25,0 - 29,99",
      },

      {
        category: "otyłość I stopnia",
        BMI: "30,0 - 34,99",
      },

      {
        category: "otyłość II stopnia (duża)",
        BMI: "35,0 - 39,99",
      },

      {
        category: "otyłość III stopnia (chorobliwa)",
        BMI: "powyżej 40,0",
      },
    ],

    tableBMIHeaders: [
      { text: "Opis", value: "category", sortable: true },
      { text: "Wartość BMI", value: "BMI", sortable: true },
    ],
  }),
};
</script>
