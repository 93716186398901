<template>
  <div class="BMICalculator">
    <v-container>
      <v-row justify="center">
        <v-col cols="8">
          <h2>Kalkulator BMI - Wskaźnik masy ciała (Body Mass Index)</h2>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="8">
          <p>
            Współczynnik obliczony przez podzielenie masy ciała, w kilogramach,
            przez kwadrat wysokości, w metach.
          </p>
          <p>Tyle "teorii" :)</p>
          <p>
            Jest to bardzo prosty współczynnik mogący (pamiętaj, to tylko
            wskazówka) określić czy Twoja masa ciała jest za niska, za wysoka a
            może bardzo dobra. Jeżeli wyliczony winik jest niepokojący to
            skonsultuj to z lekarzem, cokolwiek robimy - róbmy to z głową!
          </p>
          <p>
            Pamiętaj, współczynnik BMI nie pokazuje, że Jesteś za niska / za
            niski :)
          </p>
          <br />
          <p>A teraz zapraszam do użycia prostego kalkulator BMI online:</p>
        </v-col>

        <v-col cols="2" md="2">
          <v-img
            max-width="300"
            src="https://www.runisfun.eu/_images/BMI.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>

    <bmi_calculator />
  </div>
</template>

<script>
import bmi_calculator from "../components/BMICalculator/bmi-calculator.vue";

export default {
  name: "BMICalculator",
  components: {
    bmi_calculator,
  },
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: "bieganie jest fajne! - Kalkulator BMI",
  },
};
</script>