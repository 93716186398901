import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import HRmax from '../views/hr-max.vue'
import KarvonenMethod from '../views/KarvonenMethod.vue'
import hrZones from '../views/hrZones.vue'
import Contact from '../views/Contact.vue'
import CooperTest from '../views/CooperTest.vue'
import BMICalculator from '../views/BMICalculator.vue'
import BMR_CPM from '../views/BMR_CPM.vue'
import News from '../views/News.vue'
import RunCalculator from '../views/RunCalculator.vue'
import outsideConditiones from '../views/outsideConditiones.vue'
import oxyLevel from '../views/OxyLevel.vue'

import CaloriesCalculator from '../views/CaloriesCalculator.vue'
import RunReasons from '../views/RunReasons.vue'

import Error404 from '../views/Error404.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/O-Nas',
    name: 'O-Nas',
    component: About
  },

  {
    path: '/kalkulator-tetna-maksymalnego',
    name: 'kalkulator-tetna-maksymalnego',
    component: HRmax
  },

  {
    path: '/Metoda-Karvonena',
    name: 'Metoda-Karvonena',
    component: KarvonenMethod
  },

  {
    path: '/Test-Coopera',
    name: 'Test-Coopera',
    component: CooperTest
  },
  
  {
    path: '/strefy-tetna',
    name: 'strefy-tetna',
    component: hrZones
  },

  {
    path: '/kalkulator-BMI',
    name: 'kalkulator-BMI',
    component: BMICalculator
  },

  {
    path: '/kalkulator-BMR-CPM',
    name: 'kalkulator-BMR-CPM',
    component: BMR_CPM
  },

  {
    path: '/kontakt',
    name: 'kontakt',
    component: Contact
  },

  {
    path: '/aktualnosci',
    name: 'aktualnosci',
    component: News
  },

  {
    path: '/Kalkulator-biegowy',
    name: 'kalkulator-biegowy',
    component: RunCalculator
  },

  {
    path: '/jakosc-powietrza-pogoda',
    name: 'jakosc-powietrz-pogoda',
    component: outsideConditiones
  },

  {
    path: '/pulap-tlenowy',
    name: 'pulap-tlenowy',
    component: oxyLevel
  },


  {
    path: '/kalkulator-spalania-kalorii',
    name: 'kalkulator-spalania-kalorii',
    component: CaloriesCalculator
  },

  {
    path: '/czemu-warto-biegac',
    name: 'czemu-warto-biegac',
    component: RunReasons
  },

  


  {
    path: '*',
    name: 'Error404',
    component: Error404
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
