<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="8">
        <h1>Czemu bieganie jest fajne?</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        Jest wiele osób, które nie ma wątpliwości, że jest! Ja od jakiegoś czasu
        też jestem w tej grupie.
        <br />
        Poniżej kilka odpowiedzi na tak postawione pytanie. Kolejność czysto
        losowa :)
      </v-col>
    </v-row>
    <br />
    <v-divider />
    <br />
    <v-row justify="center">
      <v-col cols="8">
        <v-expansion-panels focusable>
          <v-expansion-panel v-for="(item, i) in reasons" :key="i">
            <v-expansion-panel-header
              >{{ i + 1 }}. {{ item.reason }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              {{ item.description }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RunReasons",
  components: {},
  metaInfo: {
    title: "bieganie jest fajne! - Czemu warto biegać?",
  },
  data: () => ({
    reasons: [
      {
        reason: "Bieganie jest fajne - po prostu",
        description:
          "Po prostu tak jest :) ale ok, mam nadzieję, że poniższe punkty dokładniej to wyjaśnią",
      },
      {
        reason: "Pozwala lepiej zadbać o własne zdrowie",
        description:
          "Nim zaczniemy biegać dobrze jest skonsultować ten pomysł z lekarzem. Możemy zacząć od lekarza rodzinnego. Komplet (nawet) podstawowych badań na początek to ZAWSZE bardzo dobry pomysł, tak więc rozpoczęcie przygody z bieganiem to sprawdzenie stanu swojego zdrowia.",
      },
      {
        reason: "Zwiększa Naszą świadomość na temat Naszego organizmu",
        description:
          'Mam tu na myśli nie tylko badania i rozmowę z lekarzem, ale bieganie pozwala Nam "nauczyć się" lepiej swojego organizmu. Na jaki wysiłek możemy sobie pozwolić, co jest dla Nas bezpieczne, do jakiego tętna możemy spokojnie ćwiczyć, ...',
      },
      {
        reason: "Lepsza kondycja",
        description:
          "To chyba oczywiste! Regularne bieganie ułatwi nam wspinaczkę, z zakupami, do mieszkania, poprawi ogólną kondycję Naszego organizmu, pozwoli na wykonywanie z uśmiechem tych czynności, wymagających kondycji, które wcześniej były dla Nas trudne.",
      },
      {
        reason: "Lepsza sylwetka",
        description:
          "To również nie jest nic odkrywczego. Regularnie wykonywane ćwiczenia, w tym wypadku bieganie, pozytywnie wpłynie na Naszą sylwetkę. Będziemy wyglądać lepiej (tak, wiem, to kwestia gustu a ja uogólniam). Jest spora szansa, że będziemy chodzić bardziej wyprostowani a nasz krok będzie pewniejszy. Jest też szansa na pozbycie się kilku kilogramów, którym chcemy powiedzieć: żegnajcie!",
      },
      {
        reason: "Spalanie kalorii",
        description:
          "Bieganie jest na szczycie listy wykonywanych czynności podczas których ilość spalanych kalorii jest największa. Szczegóły można sprawdzić w dziale kalkulator kalorii.",
      },
      {
        reason: "Biegać można (prawie) wszędzie",
        description:
          "Nie jest potrzebne specjalne boisko, stok, jezioro, czy co tam sobie wymyślimy. Biegać można w lesie, parku, po chodniku, na bieżni stacjonarnej czy przy jakiejś szkole. Możliwości jest bardzo dużo. Łatwiej znaleźć miejsce, gdzie można biegać niż takie, gdzie biegać się nie da.",
      },
      {
        reason: "Można poznać nowe osoby",
        description:
          "Jeśli lubisz poznawać nowe osoby to bieganie może w tym pomóc. Rozejrzyj się dookoła. Widzisz, ile osób biega? Jak w każdej grupie - są tam różne osoby, ale z całą pewnością jest wiele osób wartych poznania.",
      },
      {
        reason: "Jest dobrym punktem w CV",
        description:
          "Okazuje się, że taki punkt w CV jest mile widziany przez Twojego przyszłego pracodawcę. Osoby biagające mają dodatkowy plus na starcie.",
      },
      {
        reason: "Poznajemy okolice",
        description:
          "Świat z perspektywy biegacza wygląda inaczej niż przez szybę samochodu. Mamy więcej czasu żeby zauważyć szczegóły, które umykają Nam w codziennym pędzie. Czasem można odnaleźć niesamowite miejsce blisko Naszego miejsca zamieszkania. Czasem można zwiedzić okolicę. Możliwości jest tutaj bardzo dużo.",
      },
      {
        reason: "Rozładowuje stres",
        description:
          "Tego chyba nie trzeba bardziej wyjaśniać. Czasem po bardzo trudnym dniu pomaga Nam książka, czasem spacer, czasem rozmowa czy serial, ale z całą pewnością bieganie powinno się znaleźć na takiej liście. Czasem wysiłek fizyczny pozwala Nam złapać dystans do codziennych problemów. ",
      },
      {
        reason: "Nie straszna nam pogoda",
        description:
          "Bieganie wzmacnia odporność. A od tego już blisko żeby przekonać się, że (prawie) zawsze jet dobra pogoda żeby biegać. Nie straszny mróz czy deszcz. Warto sprawdzić samemu.",
      },
      {
        reason: "Wzmacniamy płuca",
        description:
          "To chyba też oczywiste. A jeśli możemy biegać tam gdzie powietrze jest czyste to jeszcze lepiej. Zachęcam do sprawdzenia stanu powietrza w jednym z działów dostępnych na tej stronie.",
      },
      {
        reason: "Niektóre przysmaki są nam łatwiej wybaczone",
        description:
          "Nie zapytam czy lubisz (tak jak ja) podjadać. Nadmienię jedynie, że bieganie to spalanie dużych ilości kalorii, dlatego czasem można przymknąć oko na jakąś mniej zdrową przekąskę (oczywiście w granicach rozsądku).",
      },
      {
        reason: "Jest zaraźliwe",
        description:
          "Przez to, że jest takie fajne to nie trudno namówić kolejne osoby do tego żeby również zaczęły biegać. Często początek jest trudny, niektórzy rezygnują, ale warto zachęcać innych do tego żeby sami sprawdzili czy bieganie jest dla Nich.",
      },
      {
        reason: "Dłuższe życie",
        description:
          'Z całą pewnością bardzo łatwo znajdziecie różne informacje na ten temat. Podobno są badania, nie wiem czy wykonane przez "amerykańskich naukowców", ale nawet jeśli nie to coś mi w środku mówi, że zdrowy tryb życia, którego bieganie może być ważnym elementem, może mieć wpływ na długość i jakość życia.',
      },
      {
        reason: "Wzmacniamy organizm (mięśnie, kości, ...)",
        description:
          "Jedyne co mogę tutaj napisać to zachęcić Państwa żebyście się sami przekonali, jak to jest, po kilku tygodniach regularnych biegów.",
      },
      {
        reason: "Zwiększa odporność",
        description:
          'Znam kilka osób, które regularnie biegają i jakoś zbyt często nie słyszę żeby te osoby miały "katar". Upraszczam? Pewnie tak, ale instynkt mi podpowiada, że coś w tym jednak jest.',
      },
      {
        reason: "Chwila tylko dla siebie",
        description:
          "Czasem potrzebujemy godzinki tylko dla siebie. Chcesz się wyciszyć? A może zmęczyć? Bieganie w samotności jest fajne i może być czasem tylko dla Ciebie! Dodatkowo biegając można słuchać ulubionej muzyki, a może audiobooka?",
      },
      {
        reason: "Mocniejsze serce",
        description:
          "Prawdziwość tego punktu może być bardzo łatwo udowodniona. Sprawdź swoje tętno spoczynkowe. Sprawdź tętno podczas wysiłku. A potem zrób to samo po kilku tygodniach biegania. Całkiem możliwe, że będziesz bardzo pozytywnie zaskoczony.",
      },
      {
        reason: "Wspiera działanie mózgu",
        description:
          "Wzmacniamy serce, płuca, ale też i mózg. Wysiłek na świeżym powietrzu ma bardzo dobry wpływ na Nasz umysł. Czasem nawet krótki wysiłek pozwala Nam znaleźć rozwiązanie jakiegoś zadania, które jeszcze przed chwilą takiego rozwiązania nie miało. Nie znam się na tym więc nie będę pisał co wytwarza mózg podczas biegania, to pewnie już wiecie :)",
      },
      {
        reason: "Cellulit",
        description:
          "Na tym też się nie znam, ale skoro to moja strona to mogę coś napisać. Nie, nie mogę. I nie będę. Niemniej jednak zapewne jest jakiś związek ze znikaniem celllitu i bieganiem.",
      },
      {
        reason: "Pozwala nam nauczyć się nowych rzeczy",
        description:
          "Bieganie to nie tylko odkrywanie nowych miejsc. To również ogromna wiedza z tym związana. Z różnymi parametrami biegu, z tym jak układać plany treningowe, jak się odżywiać, w czym biegać. Lista jest długa i sądzę, że każdy sam może dopisać tu wiele przykładów czego się nauczył dzięki temu, że zaczął biegać.",
      },
      {
        reason: "Wspomaga uprawianie innych dyscyplin sportowych",
        description:
          'Jeśli grasz w piłkę czy jeździsz na rowerze czy uprawiasz jakikolwiek inny sport - doskonale wiesz, że kondycja jest ważna, jest pewnego rodzaju "fundamentem", a dzięki bieganiu słaba kondycja Ci nie grozi.',
      },
      {
        reason: "Doba ma więcej godzin",
        description:
          "Zaskakujące? Prowokujące? Takie miało być! Jeśli masz hobby, które sprawia Ci przyjemność to zawsze znajdziesz czas, który temu hobby poświęcisz. Ja wiem, że doba ma zawsze 24h, ale czasem chęć pobiegania wzmaga Naszą kreatywnośc, sprawia, że marnujemy mniej czasu a przez to możemy mieć wrażenie, że doba jest dłuższa, że robimy więcej rzeczy w ciągu dnia niż kiedyś...",
      },
      {
        reason: "Zwracamy większą uwagę na zdrowe odżywianie",
        description:
          "Tak, wiem, pisałem już o podjadaniu, ale przecież tylko czasem podjadamy. Bieganie wymaga energi, bardzo szybko organizm da Nam sygnał jeśli Nasza dieta nie jest dobra, bardzo szybko poczujemy czego Nam brakuje. Bieganie może ułatwić zmianę nawyków żywioniowych w sposób wręcz dla Nas niezauważony.",
      },
      {
        reason: "Imprezy, medale, ...",
        description:
          "Dla wielu osób bardzo ważna jest możliwość wystartowania w różnego rodzaju zawodach. Zdobycie medalu. Kibice. Pokonanie własnych słabości. Inni biegacze. Adrenalina. A czasem coś co Nas pchnie do zwiedzenia nowego miasta, do którego byśmy nie pojechali gdyby nie maraton... A medale? Moim zdaniem nie jest to przejaw próżności a pamiątka po udziale w zawodach, które poprzedziły tygodnie treningów.",
      },
      {
        reason: "Możliwość rywalizacji (z innymi lub/i z samym sobą)",
        description:
          "Bieganie jest sportem, w który można co jakiś czas poprawiać swoje własne wyniki. Możemy przecież biec odrobinę dłużej lub dalej. A może szybciej? Możliwości jest wiele. Nie jestem fanem robienia życiówek na każdym treningu, ale uczucie kiedy udaje Nam się osiągnąć kolejny postawiony sobie cel jest przyjemne.",
      },
      {
        reason: "Ułatwia pracę przy komputerze",
        description:
          "No dobra, nie tylko przy komputerze. Powiedzmy, że, w uproszczeniu, chodziło mi o to, że ból pleców, tak częsty przy wykonywaniu pracy biurowej, jakoś się magicznie zmniejsza z kolejnymi przebiegniętymi kilometrami.",
      },
      {
        reason: "Większa pewność siebie",
        description:
          "Pokonie własnych słabości, udowodnienie sobie i innym, że dam radę, że będę biegać, że te 10km, które jest dzisiaj nieosiągalne kiedyś przebiegnę za jednym razem sprawia, że czujemy się dużo pewniejsi siebie i trochę trudniej jest Nas powstrzymać (nie tylko przed bieganiem).",
      },
      {
        reason: "Mamy wymówkę żeby kupić kilka gadżetów",
        description:
          "Choć gadżety za Nas nie biegają to mogą Nas wspierać. A skoro biegamy regularnie to (na przykład) zegarek biegowy jest już trochę mniej gadżetem :)",
      },
      {
        reason: "Każdy może biegać, prawie każdy",
        description:
          'Zacznę od tego, że bieganie nie wymaga jakiś specjalnych zdolności. Biegać może i wysoki i niski (wiecie co mam na myśli). Chodzi mi o to, że pewne predyspozycje wynikające z budowy Naszego ciała nie są aż tak ważne jak w innych sportach. Oczywiście jest grupa osób, która biegać nie powinna i dlatego na tej liście jest informacja, żeby Nasz spontaniczny pomysł zostania biegaczem skonsultować najpierw z lekarzem. Cokolwiek robimy, nie tylko chodzi mi o bieganie, starajmy się robić rozsądnie i "z głową". A jeśli ktoś dotarł aż tutaj i przeczytał to wszystko to jest mi bardzo miło :)',
      },
    ],
  }),
};
</script>

