<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10">
        <h1>BMR/CPM - dzienne zapotrzebowanie na kalorie</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <p>
          <b>BMR (Basal Metabolic Rate)</b> czy po polsku - PPM (wskaźnik Podstawowej Przemiany materii). 
          <br />
          Jest to "miara" pokazująca najmniejsze dziennje zapotrzebowanie kaloryczne, czyli minimalna ilość kalorii, która pozwoli nam zachować wszystkie podstawowe czynności życiowe. 
          <br />
          Poniższe wartości wyliczone są według dwóch metod: 
          <ul>
            <li>Metody Harrisa-Benedicta</li>
            <li>Metody Mifflin-St Jeor</li>
          </ul>  
        </p>

        <p>
          <b>CPM</b> dobowe zapotrzebowanie na kalorie uwzględniające aktywność wizyczną. Aktywności podzielone są na pięć grup i każda z tych grup ma przelicznik przez który mnoży się wynik BMR.
        </p>

        <p>
          Proszę potraktować te wartości jako punkt odniesienia, pewnego rodzaju uśrednienie, wynik działania matematycznego. Każdy z Nas jest inny i precyzyjne wartości powinny był wyliczone dla każdego indywidualnie z uwzględnieniem większej ilości parametrów!
        </p>
        
      </v-col>
    </v-row>
    <BMR_CPM_calc />
  </v-container>
</template>

<script>
import BMR_CPM_calc from '../components/BMR_CPM/BMR_CPM.vue'////////////////////////////
export default {
  name: "BMR_CPM",
  components: {
    BMR_CPM_calc,
  },
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: "Bieganie jest fajne! - BMR/CPM - dzienne zapotrzebowanie na kalorie",
  },
};
</script>
