<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10">
        <h1>Pułap tlenowy!</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <h2>Pułap tlenowy</h2>
        <p>
          VO<sub>2</sub> max czyli zdolność pochłaniania tlenu przez organizm. 
          Kolejny, obok, na przykład <router-link to="/Test-Coopera">testu Coopera</router-link>, wskaźnik pozwalający na wyznaczenie (oszacowanie) wydolności fizycznej, a w tym przypadku, z naciskiem
          na wydolność tlenową.
        </p>
        <p>
          Wartość tego współczynnika, na ogół, szacuje się w następujący sposób:
          <ul>
            <li>Na podstawie wyników <router-link to="/Test-Coopera">testu Coopera</router-link> stosując wzór: (dystans podany w metrach - 504,9) / 44,73</li>
            <li>Na podstawie pulsu maksymalnego i spoczynkowego stosując wzór: (tętno maksymalne - tętno spoczynkowe) * 15</li>
            <li>Na podstawie wieku, wagi i tętna spoczynkowego, stosując wzór: 3,542 - (0,014 * wiek + 0,015 * waga - 0,011 * tętno spoczynkowe)</li>
          </ul>
        </p>
        <p>
          Pamiętajcie, że to tylko szacowanie wartości tego współczynnika, jeżeli chcecie poznać dokładną wartość to trzeba wykonać badanie wytrzymałościowe.
        </p>
        <p>
          Poniżej znajdują się tabele pozwalające zinterpretować otrzymane wyniki.
          <br />
          <br />
          <v-divider />
          <br />
          No dobrze, przejdźmy do Naszych obliczeń:
        </p>
      </v-col>
    </v-row>
    <Oxy_level />
  </v-container>
</template>

<script>
import Oxy_level from '../components/OxyLevel/OxyLevel.vue'
export default {
  name: "OxyLevel",
  components: {
    Oxy_level,
  },
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: "Bieganie jest fajne! - Pułap tlenowy",
  },
};
</script>
