<template>
  <v-form v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="firstname"
            :rules="nameRules"
            :counter="40"
            label="Imię i nazwisko"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" md="8">
          <v-textarea
            outlined
            v-model="messageContent"
            name="messageContent"
            label="Co fajnego chcesz do Nas napisac?"
            value=""
          ></v-textarea>
        </v-col>
        <v-col cols="2" md="2">
          <v-img
            max-width="250"
            src="https://www.runisfun.eu/_images/contact-us.png"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="6">
          <v-text-field
            v-model="checkHuman"
            :label="capchaLabel"
            :on-change="onCapchaChange()"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="6" md="6">
          <v-btn
            :disabled="!allFieldsAreOk"
            color="success"
            class="mr-4"
            @click="sendForm()"
          >
            Wyślij
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Kominukat
          </v-card-title>

          <v-card-text>
            Twoja wiadomość została wysłana<br />
            Odpowiem najszybciej jak dam radę.<br />
            Dziękuję!<br />
            :-)
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Zamknij
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  name: "Contact",
  data: () => ({
    valid: false,
    dialog: false,
    allFieldsAreOk: false,
    correctReply: 0,
    capchaLabel: "",
    firstname: "",
    messageContent: "",
    nameRules: [
      (v) => !!v || "Pole jest wymagane",
      (v) => v.length <= 20 || "Maksymalnie 20 znaków",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    checkHuman: "",

    number1: 0,
    number2: 0,
  }),
  methods: {
    generateNumber() {
      this.number1 = Math.round(Math.random() * 20);
      this.number2 = Math.round(Math.random() * 20);

      this.correctReply = this.number1 + this.number2;

      this.capchaLabel =
        "Wpisz wynik działania: " +
        " " +
        this.number1 +
        " dodać " +
        this.number2 +
        " równa się: ";
    },

    onCapchaChange() {
      if (this.checkHuman == this.correctReply) {
        if (this.valid) {
          this.allFieldsAreOk = true;
        }
      }
    },

    sendForm() {
      if (this.allFieldsAreOk) {
        //sending request to php to send email with info

        let strAddress = "";
        if (window.location.href.includes("www")) {
          strAddress = "https://www.runisfun.eu/phpFiles/someIdea.php";
        } else {
          strAddress = "https://runisfun.eu/phpFiles/someIdea.php";
        }

        axios
          .post(strAddress, {
            firstName: this.firstname,
            email: this.email,
            messageContent: this.messageContent,
          })
          .catch((err) => console.log(err));

        //komunikat jesli sukces
        this.dialog = true;

        //czyszczenie pol
        this.firstname = "";
        this.email = "";
        this.messageContent = "";
        (this.checkHuman = ""), (this.allFieldsAreOk = false);
        //losowanie na nowo pod capcha
        this.generateNumber();
      }
    },
  },
  created: function () {
    this.generateNumber();
  },
};
</script>