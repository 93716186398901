<template>
  <div>

    <hrmax/>

  </div>
</template>

<script>
import hrmax from '../components/hr-max/hr-max.vue'
export default {
  name: 'hr-max',
  components: {
    hrmax,
  },
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: 'bieganie jest fajne! - kalkulator tętna maksymalnego'
  }
}
</script>