<template>
  <div>
    <v-container>
        <v-row justify="center">
          <v-col
            cols="8"
          >
            <h2>Kalkulator tętna maksymalnego - Metoda Karvonena</h2>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col
            cols="8"
          >
            <p>Informacje przedstawione poniżej pochodzą z <a href="https://en.wikipedia.org/wiki/Heart_rate#Karvonen_method" target="_blink">https://en.wikipedia.org/wiki/Heart_rate#Karvonen_method</a></p>
            <p>Jest to jedna z wielu metod pozwalająca na wyznaczenie stref tętna podczas wysiłku na podstawie tętna maksymalnego, które można wyliczyć używając <router-link to="/kalkulator-tetna-maksymalnego">kalkulatora tętna maksymalnego</router-link> i tętna spoczynkowego (najlepiej zmierzonego rano, po obudzeniu, przed wstaniem z łóżka). </p>
            <p>Poniższy wynik można skonfrontować z tym co pokazują <router-link to="strefy-tetna">strefy tętna</router-link> wyliczone tylko na podstawie tętna maksymalnego.</p>
            <p>Wzór wygląda następująco:</p>
            <p><b>THR = ((HRmax − HRrest) × % intensity) + HRrest</b></p>
            <br/>
            <p>Przejdźmy zatem do obliczeń:</p>
          </v-col>
        </v-row>
      </v-container>
      <KarvonenMethodComponent/>
  </div>
</template>

<script>
import KarvonenMethodComponent from '../components/KarvonenMethod/KarvonenMethod.vue'

export default {
  name: 'KarvonenMethod',
  components: {
    KarvonenMethodComponent,
  },
  metaInfo: {
    //titleTemplate: '%s | test title',
    title: 'bieganie jest fajne! - Metoda Karvonena'
  }
}
</script>